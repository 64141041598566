import style from './style/why-orient.module.scss'
import Title from "../../../../../components/Title/Title";
import Language from "../../../../../assets/translations/language";
import Text from "../../../../../components/Intents/Intent";



const WhyOrient = (props: any) => {
    
    return (
        <div className={`container ${style.container}`}>
            <Title title={Language[`service_qa_testing_orient_title`]} />

            <div className={style.containerContent}>
                {
                    props?.data?.attributes?.whyOrientQA?.whyOrientQAList && props?.data?.attributes?.whyOrientQA?.whyOrientQAList?.length > 0 && 
                    props?.data?.attributes?.whyOrientQA?.whyOrientQAList?.map((item: any, index: number) => {
                        return (
                            <div className={style.item} key={index}>
                                <div className={style.title}>
                                    <Text>{props.language ? item?.title : item?.title_en}</Text>
                                </div>
                                <div className={style.content}>
                                    <Text>{props.language ? item?.description : item?.description_en}</Text>
                                </div>
                                <div className={style.imageContainer}>
                                    <div className={`background-image ${style.image}`} style={{backgroundImage: `url('${item?.image?.data?.attributes?.url}')`}}/>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default WhyOrient