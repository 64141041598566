import Title from "../../../../../components/Title/Title"
import {Swiper,SwiperSlide} from "swiper/react"
import {Grid} from "swiper"
import Language from "../../../../../assets/translations/language"
import Text from "../../../../../components/Intents/Intent"

//images

//styles
import "swiper/css"
import "swiper/css/grid"
import style from "./style/how-do-we-work.module.scss"

const HowDoWeWork = (props:any) => {
    

    return (
        <div className={`container ${style.container}`}>
            <Title title={Language.service_ui_ux_how_work_title}/>
            <div className={style.mainDescription}>
                <Text>{props.language ? props?.data?.attributes?.howDoWeWork?.description :  props?.data?.attributes?.howDoWeWork?.description_en}</Text>
            </div>
            <div className={style.mainImageContainer}>
               
                        <div className={`background-image ${style.image}`} style={{backgroundImage: `url('${props?.data?.attributes?.howDoWeWork?.image?.data?.attributes?.url}')`}}/>
              
            </div>
            <div className={style.containerContent}>
                {
                    (props?.data?.attributes?.howDoWeWork?.howDoWeWorkList && props?.data?.attributes?.howDoWeWork?.howDoWeWorkList?.length > 0) &&
                    <Swiper
                        slidesPerView={1}
                        grid={{
                            rows: 1,
                            fill: "row"
                        }}
                        spaceBetween={10}
                        modules={[Grid]}
                        breakpoints={{
                            768: {
                                grid: {
                                    rows: 2,
                                    fill: "row"
                                },
                                slidesPerView: 2,
                                spaceBetween: 35
                            },
                        }}
                        className={`serviceHowDoWeWorkSlider`}
                    >
                        {
                            props?.data?.attributes?.howDoWeWork?.howDoWeWorkList?.map((item: any, index: number) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className={style.item}>
                                            <div className={style.itemTitle}>
                                                <Text>{props.language ? item?.title :  item?.title_en}</Text>
                                            </div>
                                            <div className={style.itemContent}>
                                                <Text>{props.language ? item?.description : item?.description_en}</Text>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                }
            </div>
        </div>
    )
}
export default HowDoWeWork