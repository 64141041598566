import Text from "../../../../../components/Intents/Intent"
import React from "react"

//styles
import style from './style/collaboration.module.scss'

//images
// import img from '../../../../assets/images/image-ui-ux-collaboration.png'

const Collaboration = (props: any) => {
    return (
        <div className={`container ${style.marginContainer}`}>
            <div className={style.container}>
                <div className={style.imageContainer}>
                    <div className={`background-image ${style.image}`} style={{backgroundImage: `url('${props?.data?.attributes?.collaborationUiUx?.image?.data?.attributes?.url}')`}}/>
                </div>
                <div className={style.containerContent}>
                    <div className={style.title}>
                        <Text>{props.language ? props?.data?.attributes?.collaborationUiUx?.title : props?.data?.attributes?.collaborationUiUx?.title_en}</Text>
                    </div>
                    <div className={style.content}>
                        <p className={style.paragraph}><Text>{props.language ? props?.data?.attributes?.collaborationUiUx?.description :  props?.data?.attributes?.collaborationUiUx?.description_en }</Text></p>
                        {/* <p className={style.paragraph}><Text>{Language.service_ui_ux_collaboration_content_2}</Text></p>
                        <p className={style.paragraph}><Text>{Language.service_ui_ux_collaboration_content_3}</Text></p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Collaboration