import React from "react"
import './App.scss'
import './config/i18n'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import ServiceWebDev from "./pages/Service/ServiceWebDev/ServiceWebDev"
import Home from "./pages/Home/Home"
import ServiceAppDev from './pages/Service/ServiceAppDev/ServiceAppDev'
import ServiceSoftDev from "./pages/Service/ServiceSoftDev/ServiceSoftDev"
import ServiceUIUXDesign from "./pages/Service/ServiceUIUXDesign/ServiceUIUXDesign"
import ShowCase from "./pages/ShowCase/ShowCase"
import Contact from "./pages/Contact/Contact"
import Technologies from "./pages/Technologies/Technologies"
import ServiceQualityTesting from "./pages/Service/ServiceQualityTesting/ServiceQualityTesting"
import TechnologyPHP from "./pages/Technologies/TechnologyPHP/TechnologyPHP"
import TechnologyNodeJS from "./pages/Technologies/TechnologyNodeJS/TechnologyNodeJS"
import TechnologyReact from "./pages/Technologies/TechnologyReact/TechnologyReact"
import TechnologyVue from "./pages/Technologies/TechnologyVue/TechnologyVue"
import TechnologyAndroid from "./pages/Technologies/TechnologyAndroid/TechnologyAndroid"
import TechnologyIOS from "./pages/Technologies/TechnologyIOS/TechnologyIOS"
import TechnologyAngular from "./pages/Technologies/TechnologyAngular/TechnologyAngular"
import TechnologyFlutter from "./pages/Technologies/TechnologyFlutter/TechnologyFlutter"
import TechnologyJava from "./pages/Technologies/TechnologyJava/TechnologyJava"
import TechnologyGolang from "./pages/Technologies/TechnologyGolang/TechnologyGolang"


function App() {
    
    
    return (
        <Router>
            <Routes>
                <Route path={'/'} element={<Home/>}/>
                {/*Service*/}
                <Route path='/service/software-development' element={<ServiceSoftDev/>}/>
                <Route path='/service/website-application' element={<ServiceWebDev/>}/>
                <Route path='/service/mobile-application' element={<ServiceAppDev/>}/>
                <Route path='/service/ui-ux' element={<ServiceUIUXDesign/>}/>
                <Route path='/service/qa-testing' element={<ServiceQualityTesting/>}/>
                {/*Technology*/}
                <Route path='/technology' element={<Technologies/>}/>
                {/*back-end*/}
                <Route path='/technology/java' element={<TechnologyJava/>}/>
                <Route path='/technology/golang' element={<TechnologyGolang/>}/>
                <Route path='/technology/php' element={<TechnologyPHP/>}/>
                <Route path='/technology/nodejs' element={<TechnologyNodeJS/>}/>
                {/*front-end*/}
                <Route path='/technology/reactjs' element={<TechnologyReact/>}/>
                <Route path='/technology/vuejs' element={<TechnologyVue/>}/>
                <Route path='/technology/android' element={<TechnologyAndroid/>}/>
                <Route path='/technology/ios' element={<TechnologyIOS/>}/>
                <Route path='/technology/angular' element={<TechnologyAngular/>}/>
                <Route path='/technology/flutter' element={<TechnologyFlutter/>}/>
                 {/*Misc*/}
                <Route path='/showcase' element={<ShowCase/>}/>
                <Route path='/contact-us' element={<Contact/>}/>
            </Routes>
        </Router>
    )
}

export default (App);
