import {useRef} from "react"
import {Slider} from "./components/Slidler"

//styles
import styles from './styles/popup-desktop.module.scss'

//icons
import icPopup from '../../../../assets/icons/ic-popup-close.svg'


const PopupDesktop = (props:any) => {
    const ref = useRef(null)

    const overlayClickHandler = (e: any) => {
        if (e.target === ref.current) {
            props.handleClosePopup()
        }
    }


    return(
        <div className={styles.popup} ref={ref} onClick={overlayClickHandler}>
            <div className={styles.popupContainer}>
                <div  className={styles.popupHeader}>
                    <div className={`${styles.popupCLose} background-image`} style={{backgroundImage:`url("${icPopup}")`}} onClick={props.handleClosePopup}/>
                </div>
                <div className={styles.popupBody}>
                    <div className={styles.image}>
                        <Slider images={props.data} index={props?.item?.index || 0}/>
                    </div>
                    <div className={styles.info}>
                        <h2 className={styles.projectType}>PROJECT TYPE</h2>
                        <h2 className={`${styles.projectTitle}`}>{props.language ? props?.item?.title_web : props?.item?.title_web_en}</h2>
                        <h2 className={styles.projectType}>TECHNOLOGIES</h2>
                        <p className={styles.projectTechnology}><span className={styles.title}>Server: </span>{props.language ? props?.item?.title_server : props?.item?.title_server_en}</p>
                        <p className={styles.projectTechnology}><span className={styles.title}>Backend: </span>{props.language ? props?.item?.title_backEnd : props?.item?.title_backEnd_en}</p>
                        <p className={styles.projectTechnology}><span className={styles.title}>Databases: </span>{props.language ? props?.item?.title_database : props?.item?.title_database_en }</p>
                        <p className={styles.projectTechnology}><span className={styles.title}>Document: </span>{props.language ? props?.item?.title_doc : props?.item?.title_doc_en }</p>
                        <p className={styles.projectTechnology}><span className={styles.title}>Design: </span>{props.language ? props?.item?.title_design : props?.item?.title_design_en }</p>
                        <p className={`${styles.projectTechnology}`}><span className={styles.title}>Bug Tracker: </span>{props.language ? props?.item?.title_bugTrack : props?.item?.title_bugTrack_en}</p>
                        <h2 className={styles.projectType}>TECHNOLOGIES</h2>
                        <h2 className={styles.projectTitle}>{props.language ? props?.item?.title_techList : props?.item?.title_techList }</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PopupDesktop