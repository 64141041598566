import style from './style/testing-expertise.module.scss'
import {Swiper, SwiperSlide} from "swiper/react";

import {Autoplay, Navigation} from "swiper";
import icNext from '../../../../../assets/icons/ic-next-btn.svg'
import icPrev from '../../../../../assets/icons/ic-prev-btn.svg'
import React from "react";
import Language from "../../../../../assets/translations/language";
import Title from "../../../../../components/Title/Title";
import Text from "../../../../../components/Intents/Intent";

const TestingExpertise = (props :any) => {
    const nextRef = React.useRef(null)
    const prevRef = React.useRef(null)

    const title = Language[`service_qa_testing_testing_expertise_title`]

    return (
        <div className={style.styleContainer}>
            <div className={`container ${style.container}`}>
                <Title title={title}/>
                <div className={style.containerContent}>
                    <div className={style.btnNext} ref={nextRef}><img src={icNext} alt={'icon next'}/></div>
                    <div className={style.btnPrev} ref={prevRef}><img src={icPrev} alt={'icon prev'}/> </div>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        navigation={{
                            nextEl: nextRef.current,
                            prevEl: prevRef.current
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: true
                        }}
                        modules={[Navigation, Autoplay]}
                    >
                        {
                            props?.data?.attributes?.testingExpertise && props?.data?.attributes?.testingExpertise?.length > 0 && 
                            props?.data?.attributes?.testingExpertise?.map((slide: any, index: number) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className={style.item}>
                                            <div className={style.itemImage}>
                                                <div className={`background-image ${style.image}`} style={{backgroundImage: `url('${slide?.image?.data?.attributes?.url}')`}}/>
                                            </div>
                                            <div className={style.itemContainer}>
                                                <div className={style.title}>
                                                    <Text>{props.language ? slide?.title : slide?.title_en}</Text>
                                                </div>
                                                <div className={style.content}>
                                                    <Text>{props.language ? slide?.description : slide?.description_en}</Text>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
export default TestingExpertise;