import {Swiper, SwiperSlide} from "swiper/react"
import {Autoplay, Pagination} from "swiper"

//styles
import 'swiper/css'
import 'swiper/css/pagination'
import style from './style/staffs.module.scss'

const Staffs = (props : any) => {
    

    return (
        <div className={style.container}>
            <div className={style.containerStyle}/>
            <div className={`container ${style.staffContainer}`}>
                {
                    (props?.data && props?.data?.length > 0) &&
                    <Swiper slidesPerView={1}
                            direction={"vertical"}
                            pagination={{clickable: true}}
                            modules={[Autoplay, Pagination]}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: true
                            }}
                            breakpoints = {{
                                770: {
                                    direction: 'vertical'
                                },
                                280: {
                                    direction: 'horizontal',
                                    spaceBetween: 20
                                }
                            }}
                            className={'staffsHomeSwiper'}>
                        {
                            props?.data?.map((staff: any, index: number) => {
                                return (
                                    <SwiperSlide key={`staff_${index}`}>
                                        <div className={style.itemContainer}>
                                            <div className={style.imageContainer}>
                                                <div className={`background-image ${style.image}`} style={{backgroundImage: `url('${staff?.image?.data?.attributes?.url}')`}}/>
                                            </div>
                                            <div className={`d-flex justify-content-end flex-column ${style.staffInfoContainer}`}>
                                                <div className={style.staffName}>{props.language ? staff?.title_name : staff?.title_name_en}</div>
                                                <div className={style.staffPosition}>{props.language ? staff?.title_position : staff?.title_position_en}</div>
                                                <div className={style.staffAddress}>{props.language ? staff?.title_address : staff?.title_address_en}</div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                }
            </div>
        </div>
    )
}
export default Staffs