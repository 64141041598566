import Language from "../../../../assets/translations/language"
import Title from "../../../../components/Title/Title"
import React, {useState} from "react"
import {Swiper, SwiperSlide} from "swiper/react"
import Text from "../../../../components/Intents/Intent"

//styles
import style from './style/what-we-do.module.scss'

//icons
import iconArrow from '../../../../assets/icons/ic-pink-arrow.svg'
import iconBlackArrow from '../../../../assets/icons/ic-black-arrow.svg'
import iconWhiteArrow from '../../../../assets/icons/ic-white-arrow.svg'

const WhatWeDo = (props: any) => {
    const title = Language.home_title_whatwedo
    const [activeIndex, setActiveIndex] = useState(0)

    
    const handleChangeActive = (index: number) => {
        setActiveIndex(index)
    }

    return (
        <div className={`container ${style.container}`}>
            <div className={style.containerTop}>
                <Title title={title}/>
                <div className={style.mainDescription}>
                    <Text>{props.language ? props?.data?.description : props?.data?.description_en}</Text>
                </div>
                <div className={`d-flex align-items-center ${style.btnReadMore}`}>
                    <span><Text>{Language.read_more}</Text></span>
                    <div className={`background-image ${style.icon}`} style={{backgroundImage: `url('${iconArrow}')`}}/>
                </div>
            </div>
            <div className={style.containerContent}>
                {
                    (props?.data1 && props?.data1?.length > 0) &&
                    <Swiper spaceBetween={15}
                            className={'whatWeDoHomeSwiper'}
                            breakpoints = {{
                                770: {
                                    slidesPerView: 3,
                                    spaceBetween: 34
                                },
                                280: {
                                    slidesPerView: 'auto'
                                }
                            }}
                    >
                        {
                            props?.data1?.map((item: any, index: number) => {
                                return (
                                    <SwiperSlide key={`item_${index}`}>
                                        <div className={`${style.item} ${index === activeIndex ? style.active : ''}`}
                                             onMouseOver={() => handleChangeActive(index)}
                                        >
                                            <div className={style.itemTitle}><Text>{props.language ? item?.title : item?.title_en}</Text></div>
                                            <div className={style.itemContent}><Text>{props.language ? item?.description : item?.description_en}</Text></div>
                                            <img src={(window.innerWidth < 769 || index === activeIndex) ? iconWhiteArrow : iconBlackArrow} alt={'icon white arrow'}/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                }
            </div>
        </div>
    )
}
export default WhatWeDo