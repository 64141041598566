import Banner from "../../../components/Banner/Banner"
import Introduce from "../../../components/Introduce/Introduce"
import Collaboration from "./components/Collaboration/Collaboration"
import HowDoWeWork from "./components/HowDoWeWork/HowDoWeWork"
import ServiceUIDesign from "./components/ServiceUIDesign/ServiceUIDesign"
import CustomerSatisfaction from "./components/CustomerSatisfaction/CustomerSatisfaction"
import Page from "../../../components/ComponentPage/Page"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb"
import React, { useEffect, useState } from "react"
import { getServiceUIUXDesign } from "../../../service/service"
import { useTranslation } from "react-i18next"
import { introductionUIUX } from "../../../interface/introductionUIUX"

interface dataUIUXDesign{
    attributes: any;
    introductionUIUX: Array<introductionUIUX>
}
const ServiceUIUXDesign = () => {

    const[dataUIUXDesign, setdataUIUXDesign] = useState<dataUIUXDesign>()
    const getData =  () => {
         getServiceUIUXDesign().then((result: any)=>{
             setdataUIUXDesign(result);

         });
    }
    const {i18n} = useTranslation();
    const language = i18n.language === "vi";
    
    useEffect(() => {
        getData();
    }, [])
    

    
    
    const breadcrumb = {
        root: "Home",
        destination: "UI/UX Design"
    }
    

    return (
        <Page>
            {
                dataUIUXDesign && dataUIUXDesign?.attributes &&
                <div>
                    <Banner/>
                    <Breadcrumb data={breadcrumb}/>
                    <Introduce data={dataUIUXDesign?.attributes?.introductionUIUX}
                               image={dataUIUXDesign?.attributes?.introductionUIUX?.image?.data?.attributes?.url} language={language}/>
                    <Collaboration data={dataUIUXDesign} language={language}/>
                    <HowDoWeWork data={dataUIUXDesign} language={language}/>
                    <ServiceUIDesign data={dataUIUXDesign} language={language}/>
                    <CustomerSatisfaction data={dataUIUXDesign} language={language}/>
                </div>
            }
        </Page>
    )
}
export default ServiceUIUXDesign