import axios from "axios";

export const getShowCase = () => {
    return axios.get( `${process.env.REACT_APP_BASE_URL}/show-case-desktop?populate=deep` ).then((res : any) =>{
        return res?.data?.data;

    });
}
export const getShowCaseList = () => {
    return axios.get( `${process.env.REACT_APP_BASE_URL}/show-case-item-list?populate=deep` ).then((res : any) =>{
        return res?.data?.data;

    });
}
export const getPopupDesktop = () => {
    return axios.get( `${process.env.REACT_APP_BASE_URL}/popup-desktop?populate=deep` ).then((res : any) =>{
        return res?.data?.data;

    });
}