import React from "react"

//styles
import styles from './styles/breadcrumb.module.scss'

const 
Breadcrumb = (props: any) => {
    return(
        <div className={`${styles.pageChange} container`}>
            <span className={styles.page} onClick={() => window.location.href=`/`}>{props?.data?.root}</span>
                {
                    props?.data?.path && props?.data?.path !== '' && <>
                        <span className={styles.operator}>	&gt;</span>
                        <span className={`${styles.page} ${props?.data?.destination !== undefined && props?.data?.destination !== null && props?.data?.destination !== '' ? '' : styles.highlight}`} onClick={() => window.location.href=`/${props?.data?.path}`}>{props?.data?.path} </span>
                    </>
                }

                {
                    props?.data?.destination && props?.data?.destination !== '' &&
                    <>
                        <span className={styles.operator}>	&gt;</span>
                        <span className={styles.content}>{props?.data?.destination}</span>
                    </>
                }
        </div>
    )
}
export default Breadcrumb
