import Text from "../../../../../components/Intents/Intent"

//styles
import style from './style/customer-satifaction.module.scss'

//images
import bgImg from '../../../../../assets/images/image-customer-satisfaction-bg.png'
import bgImgPhone from '../../../../../assets/images/image-customer-satisfaction-bg-phone.png'
// import mainImg from '../../../../assets/images/image-customer-satisfaction.png'

interface Props{
    data: any;
    language: boolean;
}

const CustomerSatisfaction = (props : Props) => {
    return (
        <div className={`background-image ${style.backgroundContainer}`} style={{backgroundImage: `url('${window.innerWidth > 769 ? bgImg : bgImgPhone}')`}}>
            <div className={`container ${style.container}`}>
                <div className={style.imageContainer}>
                    <div className={`background-image ${style.image}`} style={{backgroundImage: `url('${props?.data?.attributes?.customerSatisfaction?.image?.data?.attributes?.url}')`}}/>
                </div>
                <div className={style.contentContainer}>
                    <div className={style.title}>
                        <Text>{props.language ? props?.data?.attributes?.customerSatisfaction?.title : props?.data?.attributes?.customerSatisfaction?.title_en}</Text>
                    </div>
                    <div className={style.content}>
                        <p className={style.paragraph}>
                            <Text>{props.language ? props?.data?.attributes?.customerSatisfaction?.description: props?.data?.attributes?.customerSatisfaction?.description_en}</Text>
                        </p>
                        {/* <p className={style.paragraph}>
                            <Text>{Language.service_ui_ux_customer_satisfaction_content_2}</Text>
                        </p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CustomerSatisfaction