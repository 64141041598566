import style from "./style/service-tech-expertise.module.scss"

import Language from "../../../../../assets/translations/language"
import Text from "../../../../../components/Intents/Intent"

const ServiceTechExpertise = (props: any) => {
  return (
    <div className={`container`}>
      <div className={style.container}>
        <div className={style.mainTitle}>
          <Text>{Language[`service_soft_dev_tech_expertise_mainTitle`]}</Text>
        </div>
        <div className={style.mainContent}>
          <Text>
            {props.language
              ? props?.data?.description
              : props?.data?.description_en}
          </Text>
        </div>
        <div className={style.imageList}>
          {props?.data?.technologyExpertiseList &&
            props?.data?.technologyExpertiseList?.length > 0 &&
            props?.data?.technologyExpertiseList.map(
              (item: any, index: number) => {
                return (
                  <div className={style.imageContainer} key={index}>
                    <div
                      className={`background-image ${style.image}`}
                      style={{
                        backgroundImage: `url('${item?.image?.data?.attributes?.url}')`
                      }}
                    />
                    <span>
                      <Text>
                        {props.language ? item?.title : item?.title_en}
                      </Text>
                    </span>
                  </div>
                )
              }
            )}
        </div>
        <div className={style.rightImage}>
          <div
            className={`background-image ${style.mainImage}`}
            style={{
              backgroundImage: `url('${props?.data?.image?.data?.attributes?.url}')`
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default ServiceTechExpertise
