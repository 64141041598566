import Title from "../../../../../components/Title/Title"
import {Swiper, SwiperSlide} from "swiper/react"
import Language from "../../../../../assets/translations/language"
import Text from "../../../../../components/Intents/Intent"

//styles
import style from './style/service-ui-design.module.scss'

const ServiceUIDesign = (props: any) => {

    return (
        <div className={`container ${style.container}`}>
            <Title title={Language.service_ui_design_our_ui_design_title} className={style.titleContainer}/>
            <div className={style.mainDescription}>
                <Text>{props.language ? props?.data?.attributes?.ourUiDesign?.description : props?.data?.attributes?.ourUiDesign?.description_en}</Text>
            </div>
            <div className={style.containerSwiper}>
                {/* <div className={style.subText}><Text>{Language.service_ui_design_our_ui_design_subText}</Text></div> */}
                <Swiper
                    breakpoints={{
                        768: {
                            slidesPerView: 6
                        },
                        280: {
                            slidesPerView: 'auto'
                        }
                    }}
                    className={`serviceUIDesignSlider`}
                >
                    {
                        props?.data?.attributes?.ourUiDesign?.ourUiDesignList && props?.data?.attributes?.ourUiDesign?.ourUiDesignList?.length > 0 && 
                        props?.data?.attributes?.ourUiDesign?.ourUiDesignList?.map((item: any, index: number) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className={`${style.item}`}>
                                        <div className={style.itemTitle}><Text>{props.language ? item?.title : item?.title_en}</Text></div>
                                        <div className={style.itemContent}><Text>{props.language ? item?.description : item?.description_en}</Text></div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>

        </div>
    )
}
export default ServiceUIDesign