import Language from "../../../../../assets/translations/language"
import Text from "../../../../../components/Intents/Intent"
import Title from "../../../../../components/Title/Title"

//styles
import style from './style/service-orient.module.scss'

// interface Props {
//     data: any,
//     language: boolean
// }

const ServiceOrient = ( props: any) => {
    const title = Language.service_orient_app_mainTitle

    

    return (
        <div className={`container ${style.container}`}>
            <div className={style.containerTop}>
                <Title title={title}/>
                <span className={style.mainContent}><Text>{props.language ? props?.data?.attributes?.whyOrientMobile?.description : props?.data?.attributes?.whyOrientMobile?.description_en}</Text></span>
            </div>
            <div className={style.containerBottom}>
                {
                    props?.data?.attributes?.whyOrientMobile?.whyOrientMobileList?.length > 0 &&
                    props?.data?.attributes?.whyOrientMobile?.whyOrientMobileList?.map((item: any, index: number)  => {
                            return (
                                <div className={`${style.itemContainer}`} key={index}>
                                    <div className={style.contentContainer}>
                                        <p className={style.subTitle}><Text>{props.language ? item?.title : item?.title_en}</Text></p>
                                        <p className={style.subContent}><Text>{props.language ? item?.description : item?.description_en}</Text></p>
                                    </div>
                                    <div className={`background-image ${style.itemImage}`}
                                            style={{backgroundImage: `url('${item?.image?.data?.attributes?.url}')`}}/>
                                </div>
                            )
                        })
                }
            </div>
        </div>
    )
}
export default ServiceOrient