import TechnologyComponent from "../components/TechnologyComponent/TechnologyComponent"
import { useEffect, useState } from "react"

//images
import { getTechnologyAndroid } from "../../../Technologies/Technologies"
import { useTranslation } from "react-i18next"
import { introductionAndroid } from "../../../interface/introductionAndroid"

interface dataTechAndroid{
    attributes: any;
    introductionAndroid: Array<introductionAndroid>

}
const TechnologyAndroid = () => {
    const [dataTechAndroid, setDataTechAndroid] = useState<dataTechAndroid>();
    const getData = () => {
        getTechnologyAndroid().then((result : any)=>{
            setDataTechAndroid(result);

        });
    }
    const {i18n} = useTranslation();
    const language = i18n.language === "vi";
    
    useEffect(() => {
        getData();
    }, [])

    return <TechnologyComponent 
            data1={dataTechAndroid?.attributes?.introductionAndroid}
            data2={dataTechAndroid?.attributes?.ourDataServiceAndroid}
            data3={dataTechAndroid?.attributes?.ourDataServiceAndroid?.ourDataServiceList}
            data4={dataTechAndroid?.attributes?.techSliderAndroid}
            data5={dataTechAndroid?.attributes?.techSliderAndroid?.techSliderList}
            data6={dataTechAndroid?.attributes?.augmentAndroid}
            data7={dataTechAndroid?.attributes?.techToolAndroid?.techToolList}
            data8={dataTechAndroid?.attributes?.techToolAndroid}
            language={language}
    
    />
}

export default TechnologyAndroid