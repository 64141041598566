import React, {useState} from "react"
import Title from "../../Title/Title"
import Text from "../../Intents/Intent"
import {mobileStrategy} from "../../../interface/mobileStrategy"

//styles
import style from './style/service-activities-phone.module.scss'
interface Props{
    
    title: string;
    language: boolean;
    data:Array<mobileStrategy>;

}
const ServiceActivitiesPhone = (props: Props) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const setActive = (index: number) => {
        setSelectedIndex(index)
    }

    return (
        <div className={`${style.styleContainer}`}>
            <div className={`container ${style.container}`}>
                <div className={`${style.containerTop}`}>
                    <Title title={props?.title}/>
                </div>
                <div className={style.containerBottom}>
                    <div className={style.containerBottomLeft}>
                        {
                            props?.data && props?.data?.length > 0 &&
                            props?.data?.map((link: any, index: number) => {
                                return (
                                    <div key={index}>
                                        <div className={`${style.tab} ${index === selectedIndex ? style.activeTab : style.tabLinks}`}
                                            key={`link_${index}`}
                                            onClick={() => {
                                                setActive(index)
                                            }}
                                        >
                                            <span className={style.connectLine}/>
                                            <p className={style.tabTitle}><Text>{props.language ? link?.title : link?.title_en}</Text></p>
                                        </div>
                                        <div className={`${style.contentContainer} ${index === selectedIndex ? style.active : ''}`}>
                                            <p className={style.content}><Text>{props.language ? link?.description : link?.description_en}</Text></p>
                                            <div className={style.imageContainer}>
                                                <div className={`background-image ${style.image}`}
                                                     style={{backgroundImage: `url('${link?.image?.data?.attributes?.url}')`}}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ServiceActivitiesPhone