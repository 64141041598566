import React from 'react'
import Language from "../../../assets/translations/language"
import Text from "../../Intents/Intent"
import {useTranslation} from "react-i18next"
//styles
import styles from './styles/footer.module.scss'
//icons & images
import logoEn from '../../../assets/images/image-logo-en.svg'
import logoVi from '../../../assets/images/image-logo-vi.svg'
import icYoutube from '../../../assets/icons/ic-youtube.svg'
import icInstagram from '../../../assets/icons/ic-instagram.svg'
import icFacebook from '../../../assets/icons/ic-facebook.svg'



const Footer = (props: any) => {
    
   const {i18n} = useTranslation()
   
   
    return(
        <div className={styles.footer}>
            {
                props?.data?.attributes &&
                <div className={`${styles.main} container`}>
                    <div className={styles.mainContent}>
                        <div className={`${styles.item} ${styles.itemFirst}`}>
                            <div className={styles.imgContainer}>
                                <div className={`${styles.img} background-image`} style={{backgroundImage:`url("${i18n?.language === 'en' ? logoEn : logoVi}")`}} />
                            </div>
                            <span className={styles.description}><Text>{props.language ? props?.data?.attributes?.description : props?.data?.attributes?.description_en}</Text></span>
                            <div className={`${styles.listIcon}`}>
                                <div className={`${styles.icon} background-image`} style={{backgroundImage:`url("${icFacebook}")`}} onClick={() => {window.location.href = props?.data?.attributes?.linkFb}}/>
                                <div className={`${styles.icon} background-image`} style={{backgroundImage:`url("${icInstagram}")`}} onClick={() => {window.location.href = props?.data?.attributes?.linkIns}} />
                                <div className={`${styles.icon} background-image`} style={{backgroundImage:`url("${icYoutube}")`}} onClick={() => {window.location.href = props?.data?.attributes?.linkYtb}} />
                            </div>
                        </div>
                        <div className={styles.item}>
                            <h2 className={styles.itemLabel}><Text>{Language.footer_our_services_title}</Text></h2>
                            <p className={styles.itemValue} onClick={() => {window.location.href = '/service/software-development'}}><Text>{props.language ? props?.data?.attributes?.ourService?.title_item_1 : props?.data?.attributes?.ourService?.title_item_1_en}</Text></p>
                            <p className={styles.itemValue} onClick={() => {window.location.href = '/service/website-application'}}><Text>{props.language ? props?.data?.attributes?.ourService?.title_item_2 : props?.data?.attributes?.ourService?.title_item_2_en}</Text></p>
                            <p className={styles.itemValue} onClick={() => {window.location.href = '/service/mobile-application'}}><Text>{props.language ? props?.data?.attributes?.ourService?.title_item_3 : props?.data?.attributes?.ourService?.title_item_3_en}</Text></p>
                            {/*<p className={styles.itemValue}><Text>{props.language ? props?.data?.attributes?.ourService?.title_item_4 : props?.data?.attributes?.ourService?.title_item_4_en}</Text></p>*/}
                        </div>
                        <div className={styles.item}>
                            <h2 className={styles.itemLabel}><Text>{Language.footer_our_expertise_title}</Text></h2>
                            <p className={styles.itemValue} onClick={() => {window.location.href = '/service/ui-ux'}}><Text>{props.language ? props?.data?.attributes?.ourExpertise?.title_item_1 : props?.data?.attributes?.ourExpertise?.title_item_1_en}</Text></p>
                            <p className={styles.itemValue} onClick={() => {window.location.href = '/service/qa-testing'}}><Text>{props.language ? props?.data?.attributes?.ourExpertise?.title_item_2 : props?.data?.attributes?.ourExpertise?.title_item_2_en}</Text></p>
                            {/*<p className={styles.itemValue}><Text>{props.language ? props?.data?.attributes?.ourExpertise?.title_item_3 : props?.data?.attributes?.ourExpertise?.title_item_3_en}</Text></p>*/}
                            {/*<p className={styles.itemValue} onClick={() => {window.location.href = '/service/ui-ux'}}><Text>{props.language ? props?.data?.attributes?.ourExpertise?.title_item_4 : props?.data?.attributes?.ourExpertise?.title_item_4_en}</Text></p>*/}
                        </div>
                        <div className={styles.item}>
                            <h2 className={styles.itemLabel}><Text>{Language.footer_advanced_technologies_title}</Text></h2>
                            <p className={styles.itemValue} onClick={() => {window.location.href ='/technology/reactjs'}}><Text>{props.language ? props?.data?.attributes?.advancedTech?.title_item_1 : props?.data?.attributes?.advancedTech?.title_item_1_en}</Text></p>
                            <p className={styles.itemValue} onClick={() => {window.location.href ='/technology/flutter'}}><Text>{props.language ? props?.data?.attributes?.advancedTech?.title_item_2 : props?.data?.attributes?.advancedTech?.title_item_2_en}</Text></p>
                            <p className={styles.itemValue} onClick={() => {window.location.href ='/technology/golang'}}><Text>{props.language ? props?.data?.attributes?.advancedTech?.title_item_3 : props?.data?.attributes?.advancedTech?.title_item_3_en}</Text></p>
                            <p className={styles.itemValue} onClick={() => {window.location.href ='/technology/java'}}><Text>{props.language ? props?.data?.attributes?.advancedTech?.title_item_4 : props?.data?.attributes?.advancedTech?.title_item_4_en}</Text></p>
                            <p className={styles.itemValue} onClick={() => {window.location.href ='/technology/php'}}><Text>{props.language ? props?.data?.attributes?.advancedTech?.title_item_5 : props?.data?.attributes?.advancedTech?.title_item_5_en}</Text></p>
                            <p className={styles.itemValue} onClick={() => {window.location.href ='/technology/nodejs'}}  ><Text>{props.language ? props?.data?.attributes?.advancedTech?.title_item_6 : props?.data?.attributes?.advancedTech?.title_item_6_en}</Text></p>
                        </div>
                        <div className={styles.item}>
                            <h2 className={styles.itemLabel}><Text>{Language.footer_contact_us_title}</Text></h2>
                            <p className={`${styles.itemValueLink} ${styles.itemValueText} ${styles.itemValue}`}>
                                <b><Text>{Language.address_label}</Text>: </b>
                                <span><Text>{props.language ? props?.data?.attributes?.contactUs?.title_address : props?.data?.attributes?.contactUs?.title_address_en}</Text></span>
                            </p>
                            <p className={`${styles.itemValueLink} ${styles.itemValue}`} onClick={() => window.open('tel:+84933551923')}>
                                <b><Text>{Language.contact_label}</Text>: </b>
                                <span><Text>{props.language ? props?.data?.attributes?.contactUs?.title_contact : props?.data?.attributes?.contactUs?.title_contact_en}</Text></span>
                            </p>
                            <p className={`${styles.itemValueLink} ${styles.itemValue}`} onClick={() => window.open('mailto:wowivietnam.info@gmai.com')}>
                                <b>Email: </b>
                                <span><Text>{props.language ? props?.data?.attributes?.contactUs?.title_email : props?.data?.attributes?.contactUs?.title_email_en}</Text></span>
                            </p>
                        </div>
                    </div>
                    <div className={styles.mainCopyright}>
                        <p>© 2023 Copyrights, Wowi VietNam – Custom software development & engineering services. All Rights Reserved</p>
                    </div>
                    {/* <div className={`${styles.listIcon}`}>
                    <div className={`${styles.icon} background-image`} style={{backgroundImage:`url("${icFacebook}")`}} />
                    <div className={`${styles.icon} background-image`} style={{backgroundImage:`url("${icInstagram}")`}} />
                    <div className={`${styles.icon} background-image`} style={{backgroundImage:`url("${icYoutube}")`}} />
                </div> */}
                </div>
            }
        </div>
    )
}
export default  Footer;