import React from "react";
import styles from './styles/address-reach.module.scss'
import Language from "../../../../assets/translations/language";
import Text from "../../../../components/Intents/Intent";

const AddressReach = (props: any) => {
    return (
        <div className={`${styles.address} container`}>
            <div className={`${styles.addressMap}`}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.7808964376545!2d106.678985810758!3d10.82807258927934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529aee6101b7f%3A0x9d7d7b91fd3d915c!2sWowi%20Technology%20(VietNam)!5e0!3m2!1sen!2s!4v1685348145456!5m2!1sen!2s"
                    className={styles.map} loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade" title={"iframe"}></iframe>
            </div>
            <div className={styles.addressInfo}>
                <div className={styles.content}>
                    <h2><Text>{Language[`contact_within_title`]}</Text></h2>
                    <p>
                        <span className={styles.title}><Text>{Language.address_label}</Text>:</span>
                        <span className={styles.note}><Text>{props.language ? props?.data?.attributes?.addressReach?.title_address: props?.data?.attributes?.addressReach?.title_address_en}</Text></span>
                    </p>
                    <p>
                        <span className={styles.title}><Text>{Language.contact_label}</Text>:</span> <span className={styles.note}><Text>{props.language ? props?.data?.attributes?.addressReach?.title_contact: props?.data?.attributes?.addressReach?.title_contact_en}</Text></span>
                    </p>
                    <p><span
                        className={styles.title}>Email:</span>  <span className={styles.note}><Text>{props.language ? props?.data?.attributes?.addressReach?.title_email: props?.data?.attributes?.addressReach?.title_email_en}</Text></span>
                    </p>
                </div>
            </div>


        </div>
    )
}
export default AddressReach;