export const Technology = {
//    backend
    golang: 'Golang',
    java: 'Java',
    nodejs: 'NodeJs',
    php: 'PHP',

//    frontend
    reactjs: 'ReactJs',
    vuejs: 'VueJs',
    angular: 'Angular',
    flutter: 'Flutter',
    ios: 'IOS',
    android: 'Android'
}