import Introduce from "../../../components/Introduce/Introduce"
import ServiceSolution from "./components/ServiceSolution/ServiceSolution"
import ServiceOrient from "./components/ServiceOrient/ServiceOrient"
import ServiceGetStarted from "./components/ServiceGetStarted/ServiceGetStarted"
import ServiceActivitiesDesktop from "../../../components/ServiceActivities/ServiceActivitiesDesktop/ServiceActivitiesDesktop"
import Banner from "../../../components/Banner/Banner"
import Language from "../../../assets/translations/language"
import ServiceActivitiesPhone
    from "../../../components/ServiceActivities/ServiceActivitiesPhone/ServiceActivitiesPhone"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb"
import {getServiceCustomerWeb} from "../../../service/service"
import React, { useEffect, useState } from "react"
import { activitiesDuring }from "../../../interface/activitiesDuring"


import Page from "../../../components/ComponentPage/Page"

//images
import { useTranslation } from "react-i18next"
import { introductionWebDev } from "../../../interface/introductionWebDev"
import imageBanner from "../../../assets/images/image-web-banner.webp"

interface dataWebDev{
    attributes: any;
    title: string;
    description: string;
    activitiesDuring: Array<activitiesDuring>;
    data: any;
    introductionWebDev: Array<introductionWebDev>
}
const ServiceWebDev = () => {
    const [dataWebDev, setDataWebDev] = useState<dataWebDev>();
    const getData =  () => {
        getServiceCustomerWeb().then((result: any) => {
            setDataWebDev(result)
        });

    }
    
    
    const {i18n} = useTranslation();
    const language = i18n.language === "vi";
    
    useEffect(() => {
        getData();
    }, [])
    
   
    const breadcrumb = {
        root: "Home",
        destination: "Custom Web Application Development"
    }
    
    const title = Language.service_activities_mainTitle

    return (
        <Page>
            {
                dataWebDev && dataWebDev?.attributes &&
                <div>
                    <Banner imageURL={imageBanner}/>
                    <Breadcrumb data={breadcrumb}/>
                    <Introduce data={dataWebDev?.attributes?.introductionWebDev} image={dataWebDev?.attributes?.image?.data?.attributes?.url} language={language}/>
                    <ServiceSolution  data={dataWebDev} language={language}/>
                    <ServiceOrient data={dataWebDev} language={language}/>
                    <ServiceGetStarted data={dataWebDev} language={language} />
                    {
                        window.innerWidth > 768 &&
                        <ServiceActivitiesDesktop  title= {title} data={dataWebDev?.attributes?.activitiesDuring?.activitiesDuringList} language={language}/>
                    }
                    {
                        window.innerWidth <= 768 &&
                        <ServiceActivitiesPhone data={dataWebDev?.attributes?.activitiesDuring?.activitiesDuringList} language={language} title={title}/>
                    }
                </div>
            }
        </Page>

    )
}
export default ServiceWebDev