import { useEffect, useState } from "react"
import Banner from "../../components/Banner/Banner"
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb"
import Introduce from "../../components/Introduce/Introduce"
import ReachOut from "./component/ReachOut/ReachOut"
import AddressReach from "./component/AddressReach/AddressReach"
import Page from "../../components/ComponentPage/Page"
import { getContactUs } from "../../service/contact"
import { useTranslation } from "react-i18next"
import { introductionContactUs } from "../../interface/introductionContactUs"

interface dataContactUs {
  introduction: Array<introductionContactUs>
  attributes: any
}
const Contact = () => {
  const [dataContactUs, setDataContactUs] = useState<dataContactUs>()
  const getData = () => {
    getContactUs().then((result: any) => {
      setDataContactUs(result)
    })
  }
  const { i18n } = useTranslation()
  const language = i18n.language === "vi"
  useEffect(() => {
    getData()
  }, [])
  // console.log(dataContactUs);

  const breadcrumb = {
    root: "Home",
    path: "ContactUs"
  }

  return (
    <Page>
      {
        dataContactUs && dataContactUs?.attributes &&
          <div>
            <Banner />
            <Breadcrumb data={breadcrumb} />
            <Introduce
                data={dataContactUs?.attributes?.introductionContactUs}
                language={language}
            />
            <ReachOut />
            <AddressReach data={dataContactUs} language={language} />
          </div>
      }
    </Page>
  )
}
export default Contact
