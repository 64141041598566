import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import Language from "../../assets/translations/language"
import Text from "../Intents/Intent"
//styles
import styles from './styles/language-selector.module.scss'
//icons
import icVI from '../../assets/icons/ic-language-vi.svg'
import icAmerican from '../../assets/icons/ic-eng-us.svg'

const LanguageSelector = (props: any) => {
    const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'en')
    const {i18n} = useTranslation()
    const activeLanguage = props?.iconLanguage

    useEffect(() => {
        localStorage.removeItem('language')
        localStorage.setItem('language', language || 'en')
        // eslint-disable-line react-hooks/exhaustive-deps
        i18n.changeLanguage(language || 'en').then()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const changeLanguage = (language: any) => {
        setLanguage(language)
        // eslint-disable-line react-hooks/exhaustive-deps
        i18n.changeLanguage(language).then()
        props.handleLanguage(language)
    }

    return (
        <div className={styles.language} id={"select"}>
            <div className={styles.iconOption}>
                <div className={`${styles.iconOptionItem} ${activeLanguage === 'vi' ? styles.isActive : ''}`}
                     onClick={(e) => changeLanguage('vi')}>
                    <div className={`${styles.icon} background-image`}
                         style={{backgroundImage: `url("${icVI}")`}}/>
                    <Text>{Language.vi_language_text}</Text>
                </div>
                <div className={`${styles.iconOptionItem} ${activeLanguage === 'en' ? styles.isActive : ''}`}
                     onClick={(e) => changeLanguage('en')}>
                    <div className={`${styles.icon} background-image`}
                         style={{backgroundImage: `url("${icAmerican}")`}}/>
                    <Text>{Language.en_language_text}</Text>
                </div>
            </div>
        </div>
    )
}


export default LanguageSelector