import Language from "../../../../../../assets/translations/language"
import Text from "../../../../../../components/Intents/Intent"

//styles
import styles from "./styles/show-case-item.module.scss"

const ShowCaseItem = (props: any) => {
  return (
    <div className={styles.item} onClick={props.handleOpenPopup}>
      <div
        className={`${styles.itemImg} background-image`}
        style={{
          backgroundImage: `url("${props?.data?.image?.data?.attributes?.url}")`
        }}
      />
      <div className={styles.itemContent}>
        <div className={styles.itemText}>
          <p className={styles.label}>
            <Text>{Language.showcase_item_title}</Text>
          </p>
          <p className={styles.value}>
            {props.language
              ? props?.data?.title_name
              : props?.data?.title_name_en}
          </p>
        </div>
        <div className={styles.itemText}>
          <p className={styles.label}>
            <Text>{Language.showcase_item_note}</Text>:{" "}
          </p>
          <p className={styles.value}>
            {props.language
              ? props?.data?.title_web
              : props?.data?.title_web_en}
          </p>
        </div>
      </div>
    </div>
  )
}
export default ShowCaseItem
