import React from "react"
import Text from "../../../../components/Intents/Intent"

//styles
import styles from './styles/list-technology.module.scss'

const ListTechnology = (props: any) => {
    return(
        <div className={`${styles.list} container`}>
            <div className={styles.listTitle}>
                <h2> <Text>{props.language ? props?.data?.title : props?.data?.title_en }</Text> </h2>
            </div>
            <div className={styles.listItem}>
                {
                    (props?.data1 && props?.data1?.length > 0) &&
                    props?.data1?.map((item: any, index: number) => {
                        return (
                            <div className={styles.item} key={index} onClick={() => {window.location.href = `technology/${item?.title?.toLocaleLowerCase()}`}}>
                                <div className={`${styles.imageContainer} background-image`} style={{backgroundImage:`url("${item?.image?.data?.attributes?.url}")`}} />
                                <div className={styles.content}> <p>{props.language ? item?.title : item?.title_en }</p></div>
                            </div>
                        )
                    })  
                }
            </div>
        </div>
    )
}
export default ListTechnology