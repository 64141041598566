import React from "react"
import Text from "../../../../components/Intents/Intent"
import Language from "../../../../assets/translations/language"

//styles
import styles from './styles/micro-frontend.module.scss'

//images & icons

const MicroFrontEnd = (props:any) => {
    return(
        <div className={`${styles.micro} container`}>
            <div className={styles.microImg}>
                <div className={`${styles.img} background-image`} style={{backgroundImage:`url("${props?.data?.attributes?.microFrontends?.image?.data?.attributes?.url}")`}} />
            </div>
            <div className={styles.microContent}>
                <h2 className={styles.title}><Text>{Language.technology_first_micro_title}</Text></h2>
                <p className={styles.note}> <Text>{props.language ? props?.data?.attributes?.microFrontends?.description_1 :props?.data?.attributes?.microFrontends?.description_1_en}</Text></p>
                <p className={styles.note}> <Text>{props.language ? props?.data?.attributes?.microFrontends?.description_2 :props?.data?.attributes?.microFrontends?.description_2_en}</Text></p>
            </div>
        </div>
    )
}
export default MicroFrontEnd