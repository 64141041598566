import Text from "../../../../../../components/Intents/Intent"
import {Swiper, SwiperSlide} from "swiper/react"
import React from "react"
import Title from "../../../../../../components/Title/Title"

//styles
import style from './style/tech-and-tools.module.scss'

const TechAndTools = (props: any) => {
    const {data_title} = props
    return (
        <div className={style.styleContainer}>
            <div className={`container ${style.container}`}>
                <Title title={props.language ? data_title?.title_main : data_title?.title_main_en} className={style.title}/>
                <div className={style.containerContent}>
                    {
                        (props?.data && props?.data?.length > 0) &&
                        <Swiper spaceBetween={33}
                                breakpoints={{
                                    768: {
                                        slidesPerView: 4,
                                    },
                                    280: {
                                        slidesPerView: 'auto',
                                    }
                                }}
                                className={`techPythonSwiper`}
                        >
                            {
                                props?.data?.map((item: any, index: number) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div className={style.item}>
                                                <div className={style.itemTitle}>
                                                    <div className={`background-image ${style.icon}`} style={{backgroundImage: `url('${item?.image?.data?.attributes?.url}')`}}/>
                                                    <Text>{props.language ? item?.title : item?.title_en}</Text>
                                                </div>
                                                <div className={style.itemContent}>
                                                    <Text>{props.language ? item?.description : item?.description_en}</Text>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    }
                </div>
            </div>
        </div>
    )
}
export default TechAndTools