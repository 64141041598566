import React from "react"
import styles from './styles/banner.module.scss'
import imageBanner from "../../assets/images/image-web-banner.webp"

const Banner = (props: any) => {
    return (
        <div className={styles.container}>
            <div className={`background-image ${styles.containerBanner}`} style={{backgroundImage: `url('${props?.imageURL ? props?.imageURL: imageBanner}')`}}/>
        </div>
    )
}
export default Banner