import React, { useState, useEffect } from 'react'
import Banner from "../../components/Banner/Banner"
import OurExpertise from "./components/OurExpertise/OurExpertise"
import WhatWeDo from "./components/WhatWeDo/WhatWeDo"
import Staffs from "./components/Staffs/Staffs"
import WhyChooseUs from "./components/WhyChooseUs/WhyChooseUs"
import OurClient from "./components/OurClient/OurClient"
import Page from "../../components/ComponentPage/Page"
import Partners from './components/Partners/Partners'
//images
import banner from '../../assets/images/image-banner-home-desktop.webp'
import bannerPhone from '../../assets/images/image-banner-home-phone.webp'
import { useTranslation } from 'react-i18next'
import { getHome } from '../../service/service'
import { introductionHome } from '../../interface/introductionHome'
import Introduce from '../../components/Introduce/Introduce'
import Language from '../../assets/translations/language'

interface dataHome{
    attributes: any
    introductionHome:Array<introductionHome>
}

const Home = () => {
    const[dataHome, setDataHome] = useState<dataHome>()
    const getData =  () => {
        getHome().then((result: any) =>{
            setDataHome(result);
        });
   }
   const {i18n} = useTranslation();
   const language = i18n.language === "vi";
   
   
   useEffect(() => {
       getData();
   }, [])
//    console.log(dataHome);
   

    return(
        <Page>
            {
                dataHome && dataHome?.attributes &&
                <div>
                    <Banner imageURL={window.innerWidth > 769 ? banner : bannerPhone}/>
                    <Introduce data={dataHome?.attributes?.introductionHome}
                               image={dataHome?.attributes?.introductionHome?.image?.data?.attributes?.url} language={language}/>
                    <OurExpertise data={dataHome} language={language}/>
                    <WhatWeDo  data={dataHome?.attributes?.whatWeDo} data1={dataHome?.attributes?.whatWeDo?.whatWeDoList} language={language}/>
                    <Staffs data={dataHome?.attributes?.infoStaffs?.infoStaffsList} language={language}/>
                    <WhyChooseUs data={dataHome?.attributes?.whyChooseUs} data1={dataHome?.attributes?.whyChooseUs?.whyChooseUsList} language={language}/>
                    <Partners data={dataHome?.attributes?.ourPartners?.ourPartnersList}  Language={Language}/>
                    <OurClient data={dataHome?.attributes?.ourClients?.ourClientsList} language={language}/>
                </div>
            }
        </Page>
    )
}
export default Home