import {useState} from "react";
import style from './style/testing-process.module.scss'
import Title from "../../../../../components/Title/Title";
import longArrow from '../../../../../assets/images/image-long-arrow.svg'
import shortArrow from '../../../../../assets/images/image-short-arrow.svg'
import Language from "../../../../../assets/translations/language";
import Text from "../../../../../components/Intents/Intent";

const TestingProcess = (props: any) =>  {
    const [activeTabIndex, setActiveTab] = useState(0)
    

    const handleActiveTab = (index: number) => {
        setActiveTab(index)
    }
    return (
        <div className={`container ${style.container}`}>
            <div className={style.containerTop}>
                <Title title={Language[`service_qa_testing_process_title`]}/>
                <div className={style.mainDescription}>
                    <Text>{props.language ? props?.data?.attributes?.ourQATestingProcess?.description : props?.data?.attributes?.ourQATestingProcess?.description_en}</Text>
                </div>
            </div>
            <div className={style.containerBottom}>
                <div className={style.tabsContainer}>
                    {
                        props?.data?.attributes?.ourQATestingProcess?.ourQATestingProcessTab && props?.data?.attributes?.ourQATestingProcess?.ourQATestingProcessTab?.length > 0 && 
                        props?.data?.attributes?.ourQATestingProcess?.ourQATestingProcessTab?.map((tab: any, tab_index: number) => {
                            return (
                                <div className={`${style.tab}`} key={`tab_${tab_index}`} onClick={() => handleActiveTab(tab_index)}>
                                    <div className={`${style.tabTitle} ${tab_index === activeTabIndex ? style.tabActive : ''}`}>
                                        <Text>{props.language ? tab?.title : tab?.title_en}</Text>
                                    </div>
                                    <img src={shortArrow} alt={'short arrow'}/>
                                </div>
                            )
                        })
                    }
                </div>
                <img className={style.longArrow} src={longArrow} alt={'long arrow'}/>
                <div className={style.tabsContent}>
                    {
                         props?.data?.attributes?.ourQATestingProcess?.ourQATestingProcessSlide &&  props?.data?.attributes?.ourQATestingProcess?.ourQATestingProcessSlide?.length > 0 && 
                         props?.data?.attributes?.ourQATestingProcess?.ourQATestingProcessSlide?.map((tab: any, content_index:number) => {
                            return (
                                <div className={`${style.contentContainer} ${content_index === activeTabIndex ? style.active : ''}`} key={`content_${content_index}`}>
                                    <div className={style.imageContainer}>
                                        <div className={`background-image ${style.image}`} style={{backgroundImage: `url('${tab?.image?.data?.attributes?.url}')`}}/>
                                    </div>
                                    <div className={style.title}>
                                        <Text>{props.language ? tab?.title : tab?.title_en}</Text>
                                    </div>
                                    <div className={style.content}>
                                        <Text>{props.language ? tab?.description : tab?.description_en}</Text>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export default TestingProcess