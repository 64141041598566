import React, { useEffect, useState } from "react"
import Introduce from "../../../components/Introduce/Introduce"
import Content from "./component/Content/Content"
import Banner from "../../../components/Banner/Banner"
import ServiceTechExpertise from "./component/ServiceTechExpertise/ServiceTechExpertise"
import Page from "../../../components/ComponentPage/Page"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb"
import { getServiceSoftDev } from "../../../service/service"
import { useTranslation } from "react-i18next"
import { introductionSoftDev } from "../../../interface/introductionSoftDev"
import SoftwareSolution from "./component/SoftwareSolution/SoftwareSolution"
import GetStarted from "./component/GetStarted/GetStarted"
import IndustryExpertise from "./component/IndustryExpertise/IndustryExpertise"
import SoftwareDevelopment from "./component/SoftwareDevelopment/SoftwareDevelopment"
import SoftwareProcess from "./component/SoftwareProcess/SoftwareProcess"
import DevelopmentAdvantage from "./component/DevelopmentAdvantage/DevelopmentAdvantage"
import ReachOut from "../../Contact/component/ReachOut/ReachOut"

interface dataSoftDev {
  attributes: any
  introductionSoftDev: Array<introductionSoftDev>
}

interface dataSoftDev {
  attributes: any
}

const ServiceSoftDev = () => {
  const [dataSoftDev, setDataSoftDev] = useState<dataSoftDev>()
  const getData = () => {
    getServiceSoftDev().then((result: any) => {
      setDataSoftDev(result)
    })
  }
  const { i18n } = useTranslation()
  const language = i18n.language === "vi"

  useEffect(() => {
    getData()
  }, [])

  const breadcrumb = {
    root: "Home",
    destination: "Custom Software Development Services"
  }

  return (
    <Page>
        {
            dataSoftDev && dataSoftDev?.attributes &&
            <div>
                <Banner />
                <Breadcrumb data={breadcrumb} />
                <Introduce
                    data={dataSoftDev?.attributes?.introductionSoftDev}
                    image={
                        dataSoftDev?.attributes?.introductionSoftDev?.image?.data?.attributes
                            ?.url
                    }
                    language={language}
                />
                <Content data={dataSoftDev} language={language} />
                <SoftwareSolution
                    data={dataSoftDev?.attributes?.softwareSolution}
                    language={language}
                />
                <GetStarted
                    data={dataSoftDev?.attributes?.contentGetStarted}
                    language={language}
                />
                <IndustryExpertise
                    data={dataSoftDev?.attributes?.ourIndustry}
                    language={language}
                />
                <SoftwareDevelopment
                    data={dataSoftDev?.attributes?.softwareDevelopment}
                    language={language}
                />
                <SoftwareProcess
                    data={dataSoftDev?.attributes?.developmentProcess}
                    language={language}
                />
                <ServiceTechExpertise
                    data={dataSoftDev?.attributes?.technologyExpertise}
                    language={language}
                />
                <DevelopmentAdvantage
                    data={dataSoftDev?.attributes?.developmentAdvantage}
                    language={language}
                />
                <ReachOut />
            </div>
        }
    </Page>
  )
}
export default ServiceSoftDev
