import Banner from "../../../components/Banner/Banner"
import Page from "../../../components/ComponentPage/Page"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb"
import React, { useEffect, useState } from "react"
import styles from "../styles/technology.module.scss"
//images & icons
import { useTranslation } from "react-i18next"
import { introductionPHP } from "../../../interface/introductionPHP"
import { getTechnologyPHP } from "../../../Technologies/Technologies"
import ReachOut from "../../Contact/component/ReachOut/ReachOut"

interface dataTechPHP {
  attributes: any
  introductionPHP: Array<introductionPHP>
}

const TechnologyPHP = () => {
  const [dataTechPHP, setDataTechPHP] = useState<dataTechPHP>()
  const getData = () => {
    getTechnologyPHP().then((result: any) => {
      setDataTechPHP(result)
    })
  }
  const { i18n } = useTranslation()
  const language = i18n.language === "vi"

  useEffect(() => {
    getData()
  }, [])

  const breadcrumb = {
    root: "Home",
    path: "Technology",
    destination: "PHP"
  }

  return (
    <Page>
      <Banner />
      <Breadcrumb data={breadcrumb} />
      <div className={`container ${styles.container}`}>
        <h2 className={styles.title}>
          {language
            ? dataTechPHP?.attributes?.introductionPHP?.title
            : dataTechPHP?.attributes?.introductionPHP?.title_en}
        </h2>
        <p className={styles.introduce}>
          {language
            ? dataTechPHP?.attributes?.introductionPHP?.description
            : dataTechPHP?.attributes?.introductionPHP?.description_en}
        </p>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechPHP?.attributes?.introductionPHP?.image?.data?.attributes
              ?.url
          }
        />

        <h2 className={styles.title}>
          {language
            ? dataTechPHP?.attributes?.outsourcePhp?.title
            : dataTechPHP?.attributes?.outsourcePhp?.title_en}
        </h2>
        {dataTechPHP?.attributes?.outsourcePhp?.outsourcePhpList?.length >
          0 && (
          <ul className={styles.list}>
            {dataTechPHP?.attributes?.outsourcePhp?.outsourcePhpList?.map(
              (item: any, index: number) => {
                return (
                  <li key={index}>
                    <p className={styles.listItem}>
                      {language ? item?.title : item?.title_en}
                    </p>
                  </li>
                )
              }
            )}
          </ul>
        )}

        <h2 className={styles.title}>
          {language
            ? dataTechPHP?.attributes?.phpTechnology?.title
            : dataTechPHP?.attributes?.phpTechnology?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechPHP?.attributes?.phpTechnology?.image?.data?.attributes?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechPHP?.attributes?.phpTechnology?.description
            : dataTechPHP?.attributes?.phpTechnology?.description_en}
        </p>
        {dataTechPHP?.attributes?.phpTechnology?.phpTechnologyList?.length >
          0 &&
          dataTechPHP?.attributes?.phpTechnology?.phpTechnologyList?.map(
            (item: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <h3 className={styles.titleAdvantage}>
                    {language ? item?.title : item?.title_en}
                  </h3>
                  <p className={styles.introduce}>
                    {language ? item?.description : item?.description_en}
                  </p>
                </React.Fragment>
              )
            }
          )}
        <h2 className={styles.title}>
          {language
            ? dataTechPHP?.attributes?.benefitsPhp?.title
            : dataTechPHP?.attributes?.benefitsPhp?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechPHP?.attributes?.benefitsPhp?.image?.data?.attributes?.url
          }
        />
        {dataTechPHP?.attributes?.benefitsPhp?.benefitsPhpList?.length > 0 &&
          dataTechPHP?.attributes?.benefitsPhp?.benefitsPhpList?.map(
            (item: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <h3 className={styles.titleAdvantage}>
                    {language ? item?.title : item?.title_en}
                  </h3>
                  <p className={styles.introduce}>
                    {language ? item?.description : item?.description_en}
                  </p>
                </React.Fragment>
              )
            }
          )}
        <h2 className={styles.title}>
          {language
            ? dataTechPHP?.attributes?.phpPartner?.title
            : dataTechPHP?.attributes?.phpPartner?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechPHP?.attributes?.phpPartner?.image?.data?.attributes?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechPHP?.attributes?.phpPartner?.description
            : dataTechPHP?.attributes?.phpPartner?.description_en}
        </p>
      </div>
      <ReachOut />
    </Page>
  )
}
export default TechnologyPHP
