import React from "react"
import Text from "../../../../../../components/Intents/Intent"
import Title from "../../../../../../components/Title/Title"
import {Swiper, SwiperSlide} from "swiper/react"
import {Autoplay, Navigation} from "swiper"

//styles
import "swiper/css"
import "swiper/css/navigation"
import style from './style/tech-python-slider.module.scss'

const TechPythonSlider = (props: any) => {
    const {data} = props
    return (
        <div className={style.styleContainer}>
            <div className={`container ${style.container}`}>
                <Title title={props.language ? data?.title_main : data?.title_main_en}/>
                <div className={style.mainDescription}>
                    {/* <Text>{props.language ? props?.data?.description : props?.data?.description_en}</Text> */}
                </div>
                {
                    (props?.data_list && props?.data_list?.length > 0) &&
                    <div className={style.containerContent}>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={20}
                            navigation={props?.data_list?.length > 1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: true
                            }}
                            modules={[Navigation, Autoplay]}
                            className={'serviceSlider'}
                        >
                            {
                                props?.data_list?.map((slide: any, index: number) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div className={style.item}>
                                                <div className={style.itemImage}>
                                                    <div className={`background-image ${style.image}`} style={{backgroundImage: `url('${slide?.image?.data?.attributes?.url}')`}}/>
                                                </div>
                                                <div className={style.itemContainer}>
                                                    <div className={style.title}>
                                                        <Text>{props.language ? slide?.title: slide?.title_en}</Text>
                                                        
                                                    </div>
                                                    <div className={style.content}>
                                                        <Text>{props.language ? slide?.description : slide?.description_en}</Text>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                }
            </div>
        </div>
    )
}
export default TechPythonSlider;