import React, { useEffect, useState } from "react"
import Language from "../../../assets/translations/language"

//images
import img from "../../../assets/images/image-php-case-study.png"
import imgService from "../../../assets/images/image-service-java-1.png"
import imgProgress from "../../../assets/images/image-augment-java.png"
import icDB from "../../../assets/icons/ic-database.svg"
import icLib from "../../../assets/icons/ic-library.svg"
import icFramework from "../../../assets/icons/ic-framework.svg"
import icTool from "../../../assets/icons/ic-tool.svg"
import { useTranslation } from "react-i18next"
import { introductionReact } from "../../../interface/introductionReact"
import { getTechnologyReact } from "../../../Technologies/Technologies"
import Banner from "../../../components/Banner/Banner"
import Page from "../../../components/ComponentPage/Page"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb"
import styles from "../styles/technology.module.scss"
import ReachOut from "../../Contact/component/ReachOut/ReachOut"

interface dataTechReact {
  attributes: any
  introductionReact: Array<introductionReact>
}
const TechnologyReact = () => {
  const [dataTechReact, setDataTechReact] = useState<dataTechReact>()
  const getData = () => {
    getTechnologyReact().then((result: any) => {
      setDataTechReact(result)
    })
  }
  const { i18n } = useTranslation()
  const language = i18n.language === "vi"

  useEffect(() => {
    getData()
  }, [])

  const data = {
    breadcrumb: {
      root: "Home",
      path: "Technology",
      destination: "React"
    },
    generalInfo: {
      title: Language.technology_python_title,
      content: Language.technology_python_content,
      url: ""
    },
    serviceData: {
      title: Language.technology_python_slider_title,
      description: Language.technology_python_data_service_content,
      services: [
        {
          title: Language.technology_python_slider_subTitle_1,
          content: Language.technology_python_slider_subContent_1,
          image_url: imgService
        },
        {
          title: Language.technology_python_slider_subTitle_2,
          content: Language.technology_python_slider_subContent_2,
          image_url: imgService
        },
        {
          title: Language.technology_python_slider_subTitle_3,
          content: Language.technology_python_slider_subContent_3,
          image_url: imgService
        }
      ]
    },
    solutionData: {
      title: Language.technology_python_slider_title,
      description: "",
      itemList: [
        {
          title: Language.technology_python_slider_subTitle_1,
          content: Language.technology_python_slider_subContent_1,
          image_url: img
        },
        {
          title: Language.technology_python_slider_subTitle_2,
          content: Language.technology_python_slider_subContent_2,
          image_url: img
        },
        {
          title: Language.technology_python_slider_subTitle_3,
          content: Language.technology_python_slider_subContent_3,
          image_url: img
        }
      ]
    },
    progressData: {
      title: Language.technology_python_augment_title,
      content: Language.technology_python_augment_content,
      image_url: imgProgress
    },
    techAndToolsData: {
      title: Language.technology_python_tool_title,
      techAndTools: [
        {
          title: Language.technology_python_tool_subTitle_1,
          content: Language.technology_python_tool_subContent_1,
          image_url: icDB
        },
        {
          title: Language.technology_python_tool_subTitle_2,
          content: Language.technology_python_tool_subContent_2,
          image_url: icLib
        },
        {
          title: Language.technology_python_tool_subTitle_3,
          content: Language.technology_python_tool_subContent_3,
          image_url: icFramework
        },
        {
          title: Language.technology_python_tool_subTitle_4,
          content: Language.technology_python_tool_subContent_4,
          image_url: icTool
        }
      ]
    }
  }

  return (
    <Page>
      <Banner />
      <Breadcrumb data={data?.breadcrumb} />
      <div className={`container ${styles.container}`}>
        <h2 className={styles.title}>
          {language
            ? dataTechReact?.attributes?.thinkingReact?.title
            : dataTechReact?.attributes?.thinkingReact?.title_en}
        </h2>
        <p className={styles.introduce}>
          {language
            ? dataTechReact?.attributes?.thinkingReact?.description
            : dataTechReact?.attributes?.thinkingReact?.description_en}
        </p>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechReact?.attributes?.thinkingReact?.image?.data?.attributes
              ?.url
          }
        />
        <h2 className={styles.title}>
          {language
            ? dataTechReact?.attributes?.introductionReact?.title
            : dataTechReact?.attributes?.introductionReact?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechReact?.attributes?.introductionReact?.image?.data
              ?.attributes?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechReact?.attributes?.introductionReact?.description
            : dataTechReact?.attributes?.introductionReact?.description_en}
        </p>
        <h2 className={styles.title}>
          {language
            ? dataTechReact?.attributes?.whatReact?.title
            : dataTechReact?.attributes?.whatReact?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechReact?.attributes?.whatReact?.image?.data?.attributes?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechReact?.attributes?.whatReact?.description
            : dataTechReact?.attributes?.whatReact?.description_en}
        </p>
        <h2 className={styles.title}>
          {language
            ? dataTechReact?.attributes?.benefitsReact?.title
            : dataTechReact?.attributes?.benefitsReact?.title_en}
        </h2>

        <p className={styles.introduce}>
          {language
            ? dataTechReact?.attributes?.benefitsReact?.description
            : dataTechReact?.attributes?.benefitsReact?.description_en}
        </p>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechReact?.attributes?.benefitsReact?.image?.data?.attributes
              ?.url
          }
        />
        {dataTechReact?.attributes?.benefitsReact?.benifiteReactList?.length >
          0 &&
          dataTechReact?.attributes?.benefitsReact?.benifiteReactList?.map(
            (item: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <h3>{language ? item?.title : item?.title_en}</h3>
                  <p className={styles.introduce}>
                    {language ? item?.description : item?.description_en}
                  </p>
                </React.Fragment>
              )
            }
          )}
        <h2 className={styles.title}>
          {language
            ? dataTechReact?.attributes?.outsourceReact?.title
            : dataTechReact?.attributes?.outsourceReact?.title_en}
        </h2>

        <p className={styles.introduce}>
          {language
            ? dataTechReact?.attributes?.outsourceReact?.description
            : dataTechReact?.attributes?.outsourceReact?.description_en}
        </p>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechReact?.attributes?.outsourceReact?.image?.data?.attributes
              ?.url
          }
        />
        {dataTechReact?.attributes?.outsourceReact?.outsourceReactList?.length >
          0 &&
          dataTechReact?.attributes?.outsourceReact?.outsourceReactList?.map(
            (item: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <h3>{language ? item?.title : item?.title_en}</h3>
                  <p className={styles.introduce}>
                    {language ? item?.description : item?.description_en}
                  </p>
                </React.Fragment>
              )
            }
          )}
        <h2 className={styles.title}>
          {language
            ? dataTechReact?.attributes?.priceReact?.title
            : dataTechReact?.attributes?.priceReact?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechReact?.attributes?.priceReact?.image?.data?.attributes?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechReact?.attributes?.priceReact?.description
            : dataTechReact?.attributes?.priceReact?.description_en}
        </p>
      </div>

      <ReachOut />
    </Page>
  )
}

export default TechnologyReact
