import React, { useEffect, useState } from "react"
import Language from "../../../assets/translations/language"
import styles from "../styles/technology.module.scss"
//images
import img from "../../../assets/images/image-php-case-study.png"
import imgService from "../../../assets/images/image-service-java-1.png"
import imgProgress from "../../../assets/images/image-augment-java.png"
import icDB from "../../../assets/icons/ic-database.svg"
import icLib from "../../../assets/icons/ic-library.svg"
import icFramework from "../../../assets/icons/ic-framework.svg"
import icTool from "../../../assets/icons/ic-tool.svg"
import { getTechnologyJava } from "../../../Technologies/Technologies"
import { useTranslation } from "react-i18next"
import { introductionJava } from "../../../interface/introductionJava"
import Page from "../../../components/ComponentPage/Page"
import Banner from "../../../components/Banner/Banner"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb"
import ReachOut from "../../Contact/component/ReachOut/ReachOut"

interface dataTechJava {
  attributes: any
  introductionJava: Array<introductionJava>
}
const TechnologyJava = () => {
  const [dataTechJava, setDataTechJava] = useState<dataTechJava>()
  const getData = () => {
    getTechnologyJava().then((result: any) => {
      setDataTechJava(result)
    })
  }
  const { i18n } = useTranslation()
  const language = i18n.language === "vi"

  useEffect(() => {
    getData()
  }, [])
  // console.log(dataTechJava)
  const data = {
    breadcrumb: {
      root: "Home",
      path: "Technology",
      destination: "Java"
    },
    generalInfo: {
      title: Language.technology_python_title,
      content: Language.technology_python_content,
      url: ""
    },
    serviceData: {
      title: Language.technology_python_slider_title,
      description: Language.technology_python_data_service_content,
      services: [
        {
          title: Language.technology_python_slider_subTitle_1,
          content: Language.technology_python_slider_subContent_1,
          image_url: imgService
        },
        {
          title: Language.technology_python_slider_subTitle_2,
          content: Language.technology_python_slider_subContent_2,
          image_url: imgService
        },
        {
          title: Language.technology_python_slider_subTitle_3,
          content: Language.technology_python_slider_subContent_3,
          image_url: imgService
        }
      ]
    },
    solutionData: {
      title: Language.technology_python_slider_title,
      description: "",
      itemList: [
        {
          title: Language.technology_python_slider_subTitle_1,
          content: Language.technology_python_slider_subContent_1,
          image_url: img
        },
        {
          title: Language.technology_python_slider_subTitle_2,
          content: Language.technology_python_slider_subContent_2,
          image_url: img
        },
        {
          title: Language.technology_python_slider_subTitle_3,
          content: Language.technology_python_slider_subContent_3,
          image_url: img
        }
      ]
    },
    progressData: {
      title: Language.technology_python_augment_title,
      content: Language.technology_python_augment_content,
      image_url: imgProgress
    },
    techAndToolsData: {
      title: Language.technology_python_tool_title,
      techAndTools: [
        {
          title: Language.technology_python_tool_subTitle_1,
          content: Language.technology_python_tool_subContent_1,
          image_url: icDB
        },
        {
          title: Language.technology_python_tool_subTitle_2,
          content: Language.technology_python_tool_subContent_2,
          image_url: icLib
        },
        {
          title: Language.technology_python_tool_subTitle_3,
          content: Language.technology_python_tool_subContent_3,
          image_url: icFramework
        },
        {
          title: Language.technology_python_tool_subTitle_4,
          content: Language.technology_python_tool_subContent_4,
          image_url: icTool
        }
      ]
    }
  }

  return (
    <Page>
      <Banner />
      <Breadcrumb data={data?.breadcrumb} />
      <div className={`container ${styles.container}`}>
        <h2 className={styles.title}>
          {language
            ? dataTechJava?.attributes?.introductionJava?.title
            : dataTechJava?.attributes?.introductionJava?.title_en}
        </h2>
        <p className={styles.introduce}>
          {language
            ? dataTechJava?.attributes?.introductionJava?.description
            : dataTechJava?.attributes?.introductionJava?.description_en}
        </p>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechJava?.attributes?.introductionJava?.image?.data?.attributes
              ?.url
          }
        />

        <h2 className={styles.title}>
          {language
            ? dataTechJava?.attributes?.expertsInJava?.title
            : dataTechJava?.attributes?.expertsInJava?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechJava?.attributes?.expertsInJava?.image?.data?.attributes
              ?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechJava?.attributes?.expertsInJava?.description
            : dataTechJava?.attributes?.expertsInJava?.description_en}
        </p>

        <h2 className={styles.title}>
          {language
            ? dataTechJava?.attributes?.chooseJavaDev?.title
            : dataTechJava?.attributes?.chooseJavaDev?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechJava?.attributes?.chooseJavaDev?.image?.data?.attributes
              ?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechJava?.attributes?.chooseJavaDev?.description
            : dataTechJava?.attributes?.chooseJavaDev?.description_en}
        </p>
        {dataTechJava?.attributes?.chooseJavaDev?.chooseJavaDevList?.length >
          0 &&
          dataTechJava?.attributes?.chooseJavaDev?.chooseJavaDevList?.map(
            (item: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <h3 className={styles.titleAdvantage}>
                    {language ? item?.title : item?.title_en}
                  </h3>
                  <p className={styles.introduce}>
                    {language ? item?.description : item?.description_en}
                  </p>
                </React.Fragment>
              )
            }
          )}
        <h2 className={styles.title}>
          {language
            ? dataTechJava?.attributes?.outsourceJavaDev?.title
            : dataTechJava?.attributes?.outsourceJavaDev?.title_en}
        </h2>
        <p className={styles.introduce}>
          {language
            ? dataTechJava?.attributes?.outsourceJavaDev?.description
            : dataTechJava?.attributes?.outsourceJavaDev?.description_en}
        </p>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechJava?.attributes?.outsourceJavaDev?.image?.data?.attributes
              ?.url
          }
        />
        {dataTechJava?.attributes?.outsourceJavaDev?.outsourceJavaDevList
          ?.length > 0 &&
          dataTechJava?.attributes?.outsourceJavaDev?.outsourceJavaDevList?.map(
            (item: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <h3 className={styles.titleAdvantage}>
                    {language ? item?.title : item?.title_en}
                  </h3>
                  <p className={styles.introduce}>
                    {language ? item?.description : item?.description_en}
                  </p>
                </React.Fragment>
              )
            }
          )}
        <h2 className={styles.title}>
          {language
            ? dataTechJava?.attributes?.chooseWowi?.title
            : dataTechJava?.attributes?.chooseWowi?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechJava?.attributes?.chooseWowi?.image?.data?.attributes?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechJava?.attributes?.chooseWowi?.description
            : dataTechJava?.attributes?.chooseWowi?.description_en}
        </p>
      </div>
      <ReachOut />
    </Page>
  )
}

export default TechnologyJava
