
import styles from './styles/content.module.scss'
import Text from "../../../../../components/Intents/Intent"

const Content=(props:any)=>{
    return(
        <div className={`${styles.content} container`}>
            
            <div className={styles.item}>
                {
                    (props?.data?.attributes?.contentSoftDev?.contentSoftDevList && props?.data?.attributes?.contentSoftDev?.contentSoftDevList?.length > 0 ) &&
                    props?.data?.attributes?.contentSoftDev?.contentSoftDevList?.map((content: any, index: number) =>{
                        return(
                            <div className={styles.itemRight} key={index}>
                                <h2> <Text>{props.language ? content?.title : content?.title_en }</Text></h2>
                                <p>  <Text>{props.language ? content?.description : content?.description_en }</Text></p>
                            </div>
                        )
                    }) 
                }
            </div>
            {/*<div className={styles.contentLeft}>*/}
            {/*    <div className={styles.item}>*/}
            {/*        <h2> <Text>{Language["services_soft_dev_content_item_1_title"]}</Text></h2>*/}
            {/*        <p>  <Text>{Language["services_soft_dev_content_item_1_content"]}</Text></p>*/}
            {/*    </div>*/}
            {/*    <div className={styles.item}>*/}
            {/*        <h2> <Text>{Language["services_soft_dev_content_item_2_title"]}</Text></h2>*/}
            {/*        <p>  <Text>{Language["services_soft_dev_content_item_2_content"]}</Text></p>*/}
            {/*    </div>*/}
            {/*    <div className={styles.item}>*/}
            {/*        <h2> <Text>{Language["services_soft_dev_content_item_3_title"]}</Text></h2>*/}
            {/*        <p>  <Text>{Language["services_soft_dev_content_item_3_content"]}</Text></p>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className={styles.contentRight}>*/}
            {/*    <div className={styles.item}>*/}
            {/*        <h2> <Text>{Language["services_soft_dev_content_item_4_title"]}</Text></h2>*/}
            {/*        <p>  <Text>{Language["services_soft_dev_content_item_4_content"]}</Text></p>*/}
            {/*    </div>*/}
            {/*    <div className={styles.item}>*/}
            {/*        <h2> <Text>{Language["services_soft_dev_content_item_5_title"]}</Text></h2>*/}
            {/*        <p>  <Text>{Language["services_soft_dev_content_item_5_content"]}</Text></p>*/}
            {/*    </div>*/}
            {/*    <div className={styles.item}>*/}
            {/*        <h2> <Text>{Language["services_soft_dev_content_item_6_title"]}</Text></h2>*/}
            {/*        <p>  <Text>{Language["services_soft_dev_content_item_6_content"]}</Text></p>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}
export default  Content;