import React from "react"
import {useTranslation} from "react-i18next"


const Text = (props: any) => {
    const {t} = useTranslation()
    return <>{t(`${props.children}`)}</>
}

export default Text

