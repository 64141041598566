import style from "./styles/software-solution.module.scss"
import Title from "../../../../../components/Title/Title"
import Language from "../../../../../assets/translations/language"
import Text from "../../../../../components/Intents/Intent"
const ServiceSolution = (props: any) => {
  const title = Language[`service_soft_dev_software_solution_mainTitle`]
  return (
    <div className={`container ${style.container}`}>
      <div className={style.containerTop}>
        <Title title={title} />
        <div className={style.mainDescription}>
          <Text>
            {props.language
              ? props?.data?.description
              : props?.data?.description_en}
          </Text>
        </div>
      </div>
      <div className={style.containerBottom}>
        {props?.data?.softwareSolutionList &&
          props?.data?.softwareSolutionList?.length > 0 &&
          props?.data?.softwareSolutionList?.map(
            (content: any, index: number) => {
              return (
                <div className={style.item} key={index}>
                  <div className={style.itemTitle}>
                    <Text>
                      {props.language ? content?.title : content?.title_en}
                    </Text>
                  </div>
                  <div className={style.itemContent}>
                    <Text>
                      {props.language
                        ? content?.description
                        : content?.description_en}
                    </Text>
                  </div>
                </div>
              )
            }
          )}
      </div>
    </div>
  )
}
export default ServiceSolution
