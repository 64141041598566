import TechnologyComponent from "../components/TechnologyComponent/TechnologyComponent"
import { useEffect, useState } from "react"

//images
import { useTranslation } from "react-i18next"
import { introductionAngular } from "../../../interface/introductionAngular"
import { getTechnologyIOS } from "../../../Technologies/Technologies"

interface dataTechIOS{
    attributes: any;
    introductionAngular: Array<introductionAngular>

}
const TechnologyIOS = () => {
        const [dataTechIOS, setDataTechIOS] = useState<dataTechIOS>();
        const getData = () => {
            getTechnologyIOS().then((result : any)=>{
                setDataTechIOS(result);
    
            });
        }
        const {i18n} = useTranslation();
        const language = i18n.language === "vi";
        
        useEffect(() => {
            getData();
        }, [])
        // console.log(dataTechIOS)

    return <TechnologyComponent 
        data1={dataTechIOS?.attributes?.introductionAngular}
        data2={dataTechIOS?.attributes?.ourDataServiceAngular}
        data3={dataTechIOS?.attributes?.ourDataServiceAngular?.ourDataServiceList}
        data4={dataTechIOS?.attributes?.techSliderAngular}
        data5={dataTechIOS?.attributes?.techSliderAngular?.techSliderList}
        data6={dataTechIOS?.attributes?.augmentAngular}
        data7={dataTechIOS?.attributes?.techToolAngular?.techToolList}
        data8={dataTechIOS?.attributes?.techToolAngular}
        language={language}
    />
}

export default TechnologyIOS