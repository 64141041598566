import React, { useState , useEffect} from "react"
import Banner from "../../components/Banner/Banner"
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb"
import ListTechnology from "./components/ListTechnology/ListTechnology"
import Framework from "./components/Framework/Framework"
import MicroFrontEnd from "./components/MicroFrontend/MicroFrontEnd"

import Page from "../../components/ComponentPage/Page"
import { useTranslation } from "react-i18next"
import { getTechnologies } from "../../service/service"

interface dataTech {
    attributes:any;
    // data: any;
}

const Technologies = () => {
        const [dataTech, setDataTech] = useState<dataTech>();
        const getData = () => {
            getTechnologies().then((result : any)=>{
                setDataTech(result);

            });
        }
        const {i18n} = useTranslation();
        const language = i18n.language === "vi";
        
        useEffect(() => {
            getData();
        }, [])
        
        

    const breadcrumb = {
        root: "Home",
        path: "Technology",
    }
    

    return(
        <Page>
            <Banner imageURL={''}/>
            <Breadcrumb data={breadcrumb}/>
            <ListTechnology data={dataTech?.attributes?.technologiesBE} data1={dataTech?.attributes?.technologiesBE?.technologiesBEList} language={language}/>
            <ListTechnology data={dataTech?.attributes?.technologiesFE} data1={dataTech?.attributes?.technologiesFE?.technologiesFEList} language={language}/>
            <Framework data={dataTech} language={language}/>
            <MicroFrontEnd data={dataTech} language={language}/>
        </Page>
    )
}

export default  Technologies