import Title from "../../../../components/Title/Title"
import Language from "../../../../assets/translations/language"
import Text from "../../../../components/Intents/Intent"
import React from "react"

//styles
import style from './style/why-choose-us.module.scss'

//icons & images
import icItem from '../../../../assets/icons/ic-item.svg'

const title = Language.home_why_choose_us_title


const WhyChooseUs = (props: any) => {
    return (
        <div className={`container ${style.container}`}>
            <div className={style.containerLeft}>
                <Title title={title}/>
                <div className={style.mainDescription}>
                    <p><Text>{props.language ? props.data?.description_1 : props.data?.description_1_en}</Text></p>
                    <p><Text>{props.language ? props.data?.description_2 : props.data?.description_2_en}</Text></p>
                </div>
            </div>
            <div className={style.containerRight}>
                {
                    (props?.data1 && props?.data1?.length > 0) &&
                    props?.data1?.map((item: any, index: number) => {
                        return (
                            <div className={style.item} key={`item_${index}`}>
                                <div className={`background-image ${style.icon}`} style={{backgroundImage: `url('${icItem}')`}}/>
                                <p><Text>{props.language ? item?.title : item?.title_en}</Text></p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default WhyChooseUs