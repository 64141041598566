import Banner from "../../../components/Banner/Banner"
import Introduce from "../../../components/Introduce/Introduce"
import WhyOrient from "./components/WhyOrient/WhyOrient"
import TestingProcess from "./components/TestingProcess/TestingProcess"
import TestingExpertise from "./components/TestingExpertise/TestingExpertise"
import Page from "../../../components/ComponentPage/Page"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb"
import { useState, useEffect } from "react"
import { getServiceQualityTesting } from "../../../service/service"
import { useTranslation } from "react-i18next"
import { introductionQA } from "../../../interface/introductionQA"

interface dataQualityTesting{
    attributes: any;
    introductionQA: Array<introductionQA>
    
}

const ServiceQualityTesting = () => {
    const[dataQualityTesting, setdataQualityTesting ] = useState<dataQualityTesting>()
    const getData = () => {
        getServiceQualityTesting().then((result : any) =>{
            setdataQualityTesting(result)

        })
    }
    const {i18n} = useTranslation();
    const language = i18n.language === "vi";

    useEffect(() => {
        getData();
    }, [])

    
    const breadcrumb = {
        root: "Home",
        path: "Service",
        destination: "QA And Software Testing"
    }
    return (
        <Page>
            {
                dataQualityTesting && dataQualityTesting?.attributes &&
                <div>
                    <Banner/>
                    <Breadcrumb data={breadcrumb}/>
                    <Introduce data={dataQualityTesting?.attributes?.introductionQA}
                               image={dataQualityTesting?.attributes?.introductionQA?.image?.data?.attributes?.url} language={language}/>
                    <WhyOrient data={dataQualityTesting} language={language} />
                    <TestingProcess data={dataQualityTesting} language={language}/>
                    <TestingExpertise data={dataQualityTesting} language={language}/>
                </div>
            }
        </Page>
    )
}
export default ServiceQualityTesting