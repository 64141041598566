import React, { useEffect, useState } from "react"
import Language from "../../../assets/translations/language"
import styles from "../styles/technology.module.scss"
//images
import img from "../../../assets/images/image-php-case-study.png"
import imgService from "../../../assets/images/image-service-java-1.png"
import imgProgress from "../../../assets/images/image-augment-java.png"
import icDB from "../../../assets/icons/ic-database.svg"
import icLib from "../../../assets/icons/ic-library.svg"
import icFramework from "../../../assets/icons/ic-framework.svg"
import icTool from "../../../assets/icons/ic-tool.svg"
import { getTechnologyGolang } from "../../../Technologies/Technologies"
import { useTranslation } from "react-i18next"
import { introductionGolang } from "../../../interface/introductionGolang"
import Page from "../../../components/ComponentPage/Page"
import Banner from "../../../components/Banner/Banner"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb"
import ReachOut from "../../Contact/component/ReachOut/ReachOut"

interface dataTechGolang {
  attributes: any
  introductionGolang: Array<introductionGolang>
}
const TechnologyGolang = () => {
  const [dataTechGolang, setDataTechGolang] = useState<dataTechGolang>()
  const getData = () => {
    getTechnologyGolang().then((result: any) => {
      setDataTechGolang(result)
    })
  }
  const { i18n } = useTranslation()
  const language = i18n.language === "vi"
  useEffect(() => {
    getData()
  }, [])
  const data = {
    breadcrumb: {
      root: "Home",
      path: "Technology",
      destination: "Golang"
    },
    generalInfo: {
      title: Language.technology_python_title,
      content: Language.technology_python_content,
      url: ""
    },
    serviceData: {
      title: Language.technology_python_slider_title,
      description: Language.technology_python_data_service_content,
      services: [
        {
          title: Language.technology_python_slider_subTitle_1,
          content: Language.technology_python_slider_subContent_1,
          image_url: imgService
        },
        {
          title: Language.technology_python_slider_subTitle_2,
          content: Language.technology_python_slider_subContent_2,
          image_url: imgService
        },
        {
          title: Language.technology_python_slider_subTitle_3,
          content: Language.technology_python_slider_subContent_3,
          image_url: imgService
        }
      ]
    },
    solutionData: {
      title: Language.technology_python_slider_title,
      description: "",
      itemList: [
        {
          title: Language.technology_python_slider_subTitle_1,
          content: Language.technology_python_slider_subContent_1,
          image_url: img
        },
        {
          title: Language.technology_python_slider_subTitle_2,
          content: Language.technology_python_slider_subContent_2,
          image_url: img
        },
        {
          title: Language.technology_python_slider_subTitle_3,
          content: Language.technology_python_slider_subContent_3,
          image_url: img
        }
      ]
    },
    progressData: {
      title: Language.technology_python_augment_title,
      content: Language.technology_python_augment_content,
      image_url: imgProgress
    },
    techAndToolsData: {
      title: Language.technology_python_tool_title,
      techAndTools: [
        {
          title: Language.technology_python_tool_subTitle_1,
          content: Language.technology_python_tool_subContent_1,
          image_url: icDB
        },
        {
          title: Language.technology_python_tool_subTitle_2,
          content: Language.technology_python_tool_subContent_2,
          image_url: icLib
        },
        {
          title: Language.technology_python_tool_subTitle_3,
          content: Language.technology_python_tool_subContent_3,
          image_url: icFramework
        },
        {
          title: Language.technology_python_tool_subTitle_4,
          content: Language.technology_python_tool_subContent_4,
          image_url: icTool
        }
      ]
    }
  }
  return (
    <Page>
      <Banner />
      <Breadcrumb data={data?.breadcrumb} />
      <div className={`container ${styles.container}`}>
        <p className={styles.introduce}>
          {language
            ? dataTechGolang?.attributes?.introductionGolang?.description
            : dataTechGolang?.attributes?.introductionGolang?.description_en}
        </p>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechGolang?.attributes?.introductionGolang?.image?.data
              ?.attributes?.url
          }
        />

        <h2 className={styles.title}>
          {language
            ? dataTechGolang?.attributes?.advantageGolang?.title_main
            : dataTechGolang?.attributes?.advantageGolang?.title_main_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechGolang?.attributes?.advantageGolang?.image?.data?.attributes
              ?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechGolang?.attributes?.advantageGolang?.description
            : dataTechGolang?.attributes?.advantageGolang?.description_en}
        </p>
        {dataTechGolang?.attributes?.advantageGolang?.advanceGolangList
          ?.length > 0 &&
          dataTechGolang?.attributes?.advantageGolang?.advanceGolangList?.map(
            (item: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <h3>{language ? item?.title : item?.title_en}</h3>
                  <p className={styles.introduce}>
                    {language ? item?.description : item?.description_en}
                  </p>
                </React.Fragment>
              )
            }
          )}

        <h2 className={styles.title}>
          {language
            ? dataTechGolang?.attributes?.golangUsed?.title_main
            : dataTechGolang?.attributes?.golangUsed?.title_main_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechGolang?.attributes?.golangUsed?.image?.data?.attributes?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechGolang?.attributes?.golangUsed?.description
            : dataTechGolang?.attributes?.golangUsed?.description_en}
        </p>
        {dataTechGolang?.attributes?.golangUsed?.goLangUsedList?.length > 0 && (
          <ul className={styles.list}>
            {dataTechGolang?.attributes?.golangUsed?.goLangUsedList?.map(
              (item: any, index: number) => {
                return (
                  <li key={index}>
                    <p className={styles.listItem}>
                      {language ? item?.title : item?.title_en}
                    </p>
                  </li>
                )
              }
            )}
          </ul>
        )}

        <h2 className={styles.title}>
          {language
            ? dataTechGolang?.attributes?.outsourceGolang?.title_main
            : dataTechGolang?.attributes?.outsourceGolang?.title_main_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechGolang?.attributes?.outsourceGolang?.image?.data?.attributes
              ?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechGolang?.attributes?.outsourceGolang?.description
            : dataTechGolang?.attributes?.outsourceGolang?.description_en}
        </p>
        {dataTechGolang?.attributes?.outsourceGolang?.outsourceGolangList
          ?.length > 0 && (
          <ul className={styles.list}>
            {dataTechGolang?.attributes?.outsourceGolang?.outsourceGolangList?.map(
              (item: any, index: number) => {
                return (
                  <li key={index}>
                    <p className={styles.listItem}>
                      {language ? item?.title : item?.title_en}
                    </p>
                  </li>
                )
              }
            )}
          </ul>
        )}
        <p className={styles.introduce}>
          {language
            ? dataTechGolang?.attributes?.outsourceGolang?.description1
            : dataTechGolang?.attributes?.outsourceGolang?.description1_en}
        </p>
      </div>

      <ReachOut />
    </Page>
  )
}

export default TechnologyGolang
