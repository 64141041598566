import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react'
import Title from "../../../../components/Title/Title"
import {Pagination} from "swiper"
import Language from "../../../../assets/translations/language"
import Text from "../../../../components/Intents/Intent"

//styles
import "swiper/css"
import "swiper/css/pagination"
import styles from  './styles/our-expertise.module.scss'

const title = {
    title: Language.home_ourexpertise_title
}


const OurExpertise = (props: any) => {
    return (
        <div className={styles.containerOurExpertise}>
            <div className={`container ${styles.container}`}>
                <Title title={title.title} className={styles.header}/>
                {
                    (props?.data?.attributes?.ourExpertise?.ourExpertiseList && props?.data?.attributes?.ourExpertise?.ourExpertiseList?.length > 0) &&
                    <Swiper spaceBetween={16}
                            className={'ourExpertiseHomeSwiper'}
                            pagination={true}
                            breakpoints={{
                                770: {
                                    slidesPerView: 3
                                },
                                280: {
                                    slidesPerView: 'auto',
                                    spaceBetween: 18
                                }
                            }}
                            modules={[Pagination]}
                    >
                        {
                            props?.data?.attributes?.ourExpertise?.ourExpertiseList?.map((item: any, index: number) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className={styles.containerInfo}>
                                            <h4 className={styles.title}><Text>{props.language ? item?.title : item?.title_en}</Text></h4>
                                            <p className={styles.content}><Text>{props.language ? item?.description : item?.description_en}</Text></p>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                }
            </div>
        </div>


    )
}
export default OurExpertise