import React, { useEffect, useState } from "react"
import Language from "../../../assets/translations/language"
import styles from "../styles/technology.module.scss"
//images
import img from "../../../assets/images/image-php-case-study.png"
import imgService from "../../../assets/images/image-service-java-1.png"
import imgProgress from "../../../assets/images/image-augment-java.png"
import icDB from "../../../assets/icons/ic-database.svg"
import icLib from "../../../assets/icons/ic-library.svg"
import icFramework from "../../../assets/icons/ic-framework.svg"
import icTool from "../../../assets/icons/ic-tool.svg"
import { getTechnologyFlutter } from "../../../Technologies/Technologies"
import { useTranslation } from "react-i18next"
import { introductionFlutter } from "../../../interface/introductionFlutter"
import Page from "../../../components/ComponentPage/Page"
import Banner from "../../../components/Banner/Banner"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb"
import ReachOut from "../../Contact/component/ReachOut/ReachOut"

interface dataTechFlutter {
  attributes: any
  introductionFlutter: Array<introductionFlutter>
}
const TechnologyFlutter = () => {
  const [dataTechFlutter, setDataTechFlutter] = useState<dataTechFlutter>()
  const getData = () => {
    getTechnologyFlutter().then((result: any) => {
      setDataTechFlutter(result)
    })
  }
  const { i18n } = useTranslation()
  const language = i18n.language === "vi"

  useEffect(() => {
    getData()
  }, [])

  const data = {
    breadcrumb: {
      root: "Home",
      path: "Technology",
      destination: "Flutter"
    },
    generalInfo: {
      title: Language.technology_python_title,
      content: Language.technology_python_content,
      url: ""
    },
    serviceData: {
      title: Language.technology_python_slider_title,
      description: Language.technology_python_data_service_content,
      services: [
        {
          title: Language.technology_python_slider_subTitle_1,
          content: Language.technology_python_slider_subContent_1,
          image_url: imgService
        },
        {
          title: Language.technology_python_slider_subTitle_2,
          content: Language.technology_python_slider_subContent_2,
          image_url: imgService
        },
        {
          title: Language.technology_python_slider_subTitle_3,
          content: Language.technology_python_slider_subContent_3,
          image_url: imgService
        }
      ]
    },
    solutionData: {
      title: Language.technology_python_slider_title,
      description: "",
      itemList: [
        {
          title: Language.technology_python_slider_subTitle_1,
          content: Language.technology_python_slider_subContent_1,
          image_url: img
        },
        {
          title: Language.technology_python_slider_subTitle_2,
          content: Language.technology_python_slider_subContent_2,
          image_url: img
        },
        {
          title: Language.technology_python_slider_subTitle_3,
          content: Language.technology_python_slider_subContent_3,
          image_url: img
        }
      ]
    },
    progressData: {
      title: Language.technology_python_augment_title,
      content: Language.technology_python_augment_content,
      image_url: imgProgress
    },
    techAndToolsData: {
      title: Language.technology_python_tool_title,
      techAndTools: [
        {
          title: Language.technology_python_tool_subTitle_1,
          content: Language.technology_python_tool_subContent_1,
          image_url: icDB
        },
        {
          title: Language.technology_python_tool_subTitle_2,
          content: Language.technology_python_tool_subContent_2,
          image_url: icLib
        },
        {
          title: Language.technology_python_tool_subTitle_3,
          content: Language.technology_python_tool_subContent_3,
          image_url: icFramework
        },
        {
          title: Language.technology_python_tool_subTitle_4,
          content: Language.technology_python_tool_subContent_4,
          image_url: icTool
        }
      ]
    }
  }

  return (
    <Page>
      <Banner />
      <Breadcrumb data={data?.breadcrumb} />
      <div className={`container ${styles.container}`}>
        <h2 className={styles.title}>
          {language
            ? dataTechFlutter?.attributes?.introduceFlutter?.title
            : dataTechFlutter?.attributes?.introduceFlutter?.title_en}
        </h2>
        <p className={styles.introduce}>
          {language
            ? dataTechFlutter?.attributes?.introduceFlutter?.description
            : dataTechFlutter?.attributes?.introduceFlutter?.description_en}
        </p>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechFlutter?.attributes?.introduceFlutter?.image?.data
              ?.attributes?.url
          }
        />

        <h2 className={styles.title}>
          {language
            ? dataTechFlutter?.attributes?.whatFlutter?.title
            : dataTechFlutter?.attributes?.whatFlutter?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechFlutter?.attributes?.whatFlutter?.image?.data?.attributes
              ?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechFlutter?.attributes?.whatFlutter?.description
            : dataTechFlutter?.attributes?.whatFlutter?.description_en}
        </p>

        <h2 className={styles.title}>
          {language
            ? dataTechFlutter?.attributes?.whyFlutter?.title
            : dataTechFlutter?.attributes?.whyFlutter?.title_en}
        </h2>
        <p className={styles.introduce}>
          {language
            ? dataTechFlutter?.attributes?.whyFlutter?.description
            : dataTechFlutter?.attributes?.whyFlutter?.description_en}
        </p>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechFlutter?.attributes?.whyFlutter?.image?.data?.attributes
              ?.url
          }
        />
        {dataTechFlutter?.attributes?.whyFlutter?.whyFlutterList?.length > 0 &&
          dataTechFlutter?.attributes?.whyFlutter?.whyFlutterList?.map(
            (item: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <h3 className={styles.titleAdvantage}>
                    {language ? item?.title : item?.title_en}
                  </h3>
                  <p className={styles.introduce}>
                    {language ? item?.description : item?.description_en}
                  </p>
                </React.Fragment>
              )
            }
          )}
        <h2 className={styles.title}>
          {language
            ? dataTechFlutter?.attributes?.platformFlutter?.title
            : dataTechFlutter?.attributes?.platformFlutter?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechFlutter?.attributes?.platformFlutter?.image?.data
              ?.attributes?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechFlutter?.attributes?.platformFlutter?.description
            : dataTechFlutter?.attributes?.platformFlutter?.description_en}
        </p>
        <h2 className={styles.title}>
          {language
            ? dataTechFlutter?.attributes?.embracedFlutter?.title
            : dataTechFlutter?.attributes?.embracedFlutter?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechFlutter?.attributes?.embracedFlutter?.image?.data
              ?.attributes?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechFlutter?.attributes?.embracedFlutter?.description
            : dataTechFlutter?.attributes?.embracedFlutter?.description_en}
        </p>
        <h2 className={styles.title}>
          {language
            ? dataTechFlutter?.attributes?.expFlutter?.title
            : dataTechFlutter?.attributes?.expFlutter?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechFlutter?.attributes?.expFlutter?.image?.data?.attributes
              ?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechFlutter?.attributes?.expFlutter?.description
            : dataTechFlutter?.attributes?.expFlutter?.description_en}
        </p>
      </div>

      <ReachOut />
    </Page>
  )
}

export default TechnologyFlutter
