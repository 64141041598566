import Language from "../../../../assets/translations/language"
import Text from "../../../../components/Intents/Intent"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { useForm } from "react-hook-form"

//styles
import "react-toastify/dist/ReactToastify.css"
import styles from "./styles/reach-out.module.scss"
import { useTranslation } from "react-i18next"

const ReachOut = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const { t } = useTranslation()
  //

  //
  const onSubmit = (data: any) => {
    const params = {
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        workEmail: data.email,
        workPhone: data.phone,
        choosePosition: data.service,
        inbox: data.message
      }
    }
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}/contact-us-inputs`, params)
      .then((res: any) => {
        if (res?.data?.status === true) {
          toast.success("Contact inform submitted")
        } else {
          toast.error("Failed")
        }
      })
  }

  return (
    <div className={styles.reachOut}>
      <div className={`${styles.content} container`}>
        <div className={styles.contentHead}>
          <h2 className={styles.title}>
            <Text>{Language.contact_reach_title}</Text>
          </h2>
          <span className={styles.note}>
            <Text>{Language.contact_reach_note}</Text>
          </span>
        </div>
        <div className={styles.contentForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.form}>
              <div className={styles.item}>
                <div
                  className={`${errors?.firstName ? styles.error : ""} ${
                    styles.itemContainer
                  }`}
                >
                  <input
                    type={"text"}
                    placeholder={"First name *"}
                    {...register("firstName", { required: true })}
                  />
                </div>
                <div
                  className={`${errors?.lastName ? styles.error : ""} ${
                    styles.itemContainer
                  }`}
                >
                  <input
                    type={"text"}
                    placeholder={"Last name *"}
                    {...register("lastName", { required: true })}
                  />
                </div>
              </div>
              <div className={styles.item}>
                <div
                  className={`${errors?.email ? styles.error : ""} ${
                    styles.itemContainer
                  }`}
                >
                  <input
                    type={"text"}
                    placeholder={"Work email *"}
                    {...register("email", {
                      required: true,
                      pattern: /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/
                    })}
                  />
                </div>
                <div
                  className={`${errors?.phone ? styles.error : ""} ${
                    styles.itemContainer
                  }`}
                >
                  <input
                    type={"text"}
                    placeholder={"Work phone *"}
                    {...register("phone", {
                      required: true,
                      minLength: 10,
                      maxLength: 11
                    })}
                  />
                </div>
              </div>
              <div className={styles.item}>
                <select id={"select"} {...register("service")}>
                  <option value={t(Language.footer_our_expertise_title_item_1)}>
                    <Text>{Language.footer_our_expertise_title_item_1}</Text>{" "}
                  </option>
                  <option value={t(Language.footer_our_expertise_title_item_2)}>
                    <Text>{Language.footer_our_expertise_title_item_2}</Text>
                  </option>
                  <option value={t(Language.footer_our_expertise_title_item_3)}>
                    <Text>{Language.footer_our_expertise_title_item_3}</Text>
                  </option>
                  <option value={t(Language.footer_our_expertise_title_item_4)}>
                    <Text>{Language.footer_our_expertise_title_item_4}</Text>
                  </option>
                </select>
              </div>
              <div className={styles.item}>
                <textarea
                  placeholder={"Your Message..."}
                  {...register("message", { required: true, minLength: 10 })}
                />
              </div>
              <div className={styles.action}>
                <button type={"submit"}>
                  {" "}
                  <Text>{Language.contact_reach_form_btn}</Text>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
export default ReachOut
