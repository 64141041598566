import Title from "../../../../components/Title/Title"
import Language from "../../../../assets/translations/language"

//styles
import style from './style/partners.module.scss'

//icons & images
import bgImg from '../../../../assets/images/image-home-clients-bg.png'

const title = Language.home_our_partners_title


const Partners = (props: any) => {

    return (
        <div className={style.container}>
            <div className={`background-image ${style.bgImage}`} style={{backgroundImage: `url('${bgImg}')`}}/>
            <div className={`container ${style.containerContent}`}>
                <Title title={title}/>
                <div className={style.itemContainer}>
                    {
                        props?.data?.length > 0 &&
                        props?.data?.map((partner: any, index: number) => {
                            return (
                                <div className={style.imageContainer} key={index}>
                                    <div className={style.logoContainer}>
                                        <div className={`background-image ${style.logo}`} style={{backgroundImage: `url('${partner?.image?.data?.attributes?.url}')`}}/>
                                    </div>
                                    <span className={style.partnersName}>{props.language ? partner?.title : partner?.title_en}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>

    )
}
export default Partners