import React, {useState} from "react"
import Title from "../../Title/Title"
import Text from "../../Intents/Intent"
import {mobileStrategy} from "../../../interface/mobileStrategy"
// import mobileStrategyList from "../../../interface/mobileStrategyList"

//styles
import style from './style/service-activities-desktop.module.scss'


interface Props{
    
    title: string;
    language: boolean;
    data:Array<mobileStrategy>;
    

}
const ServiceActivitiesDesktop = (props: Props) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const setActive = (index: number) => {
        setSelectedIndex(index)
    }

    return (
        <div className={`${style.styleContainer}`}>
            <div className={`container ${style.container}`}>
                <div className={`${style.containerTop}`}>
                    <Title title={props?.title}/>
                </div>
                <div className={style.containerBottom}>
                    <div className={style.containerBottomLeft}>
                        {
                            (props?.data && props?.data?.length > 0) &&
                            props?.data?.map((link: any, index: number) => {
                                return (
                                    <div className={`${style.tab} ${index === selectedIndex ? style.activeTab : style.tabLinks}`}
                                         key={`link_${index}`}
                                         onClick={() => setActive(index)}
                                    >
                                        <span className={style.connectLine}/>
                                        <p className={style.tabTitle}><Text>{props.language ? link?.title : link?.title_en}</Text></p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={style.containerBottomRight}>
                        {
                            (props?.data && props?.data?.length > 0) &&
                            props?.data?.map((content: any, index: number) => {
                                return (
                                    <div className={`${index === selectedIndex ? style.active : ''} ${style.contentContainer}`} key={`content_${index}`}>
                                        <p className={`${style.content}`}><Text>{props.language ? content?.description : content?.description_en}</Text></p>
                                        <div className={`${style.imageContainer} ${index === selectedIndex ? style.active : ''}`}>
                                            <div className={`background-image ${style.image}`}
                                                 style={{backgroundImage: `url('${content?.image?.data?.attributes?.url}')`}}/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ServiceActivitiesDesktop