const { default: axios } = require("axios")

export const getTechnologies =  () => {
    return  axios.get( `${process.env.REACT_APP_BASE_URL}/technology-desktop?populate=deep` ).then((res: any) => {
        return res?.data?.data;
    });;
    
}
export const getServiceCustomerWeb =  () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/service-custom-web-application-development?populate=deep`).then((res: any) => {
        return res?.data?.data;
    });
    
}

export const getServiceCustomerMobile = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/service-custom-moblie-application-development?populate=deep`).then((res: any) => {
        return res?.data?.data;
    });
}

export const getServiceUIUXDesign = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/service-custom-ui-ux-design?populate=deep`).then((res: any) => {
        return res?.data?.data;

    });
}
export const getServiceQualityTesting = () => {
    return axios.get( `${process.env.REACT_APP_BASE_URL}/service-custom-qa-and-software-testing?populate=deep` ).then((res : any) =>{
        return res?.data?.data;

    });
}
//Service Custom QA and Software Testing

export const getServiceSoftDev = () => {
    return axios.get( `${process.env.REACT_APP_BASE_URL}/service-custom-software-development?populate=deep`).then((res: any) => {
        return res?.data?.data;

    });
}



export const getHome = () => {
    return axios.get( `${process.env.REACT_APP_BASE_URL}/home-desktop?populate=deep`).then((res: any) => {
        return res?.data?.data
    });
}


export const getFooter = () => {
    return axios.get( `${process.env.REACT_APP_BASE_URL}/footer-desktop?populate=deep`).then((res: any) => {
        return res?.data?.data;

    });
}

