import Title from "../../../../../components/Title/Title"
import Language from "../../../../../assets/translations/language"
import Text from "../../../../../components/Intents/Intent"
import React from "react"

//styles
import style from './style/service-orient.module.scss'

//icons
import icContent from '../../../../../assets/icons/ic-content.svg'

const ServiceOrient = (props: any) => {
    const title = Language.home_title_whatwedo

    return (
        <div className={`container ${style.container}`}>
            <div className={style.containerTop}>
                <Title title={title} />
                <p className={style.mainDescription}><Text>{props.language ? props?.data?.attributes?.whyOrient?.title : props?.data?.attributes?.whyOrient?.title_en}</Text></p>
            </div>
            <div className={style.containerBottom}>
                {
                    (props?.data?.attributes?.whyOrient?.whyOrientList?.length > 0) &&
                    props?.data?.attributes?.whyOrient?.whyOrientList?.map((data: any, index: number) => {
                        return (
                                <div className={style.subContainer} key={`content_${index}`}>
                                    <div className={style.subTitleContainer}>
                                        <div className={`background-image ${style.icon}`} style={{backgroundImage: `url('${icContent}')`}}/>
                                        <div className={style.subTitle}>
                                            <Text>{props.language ? data?.title : data?.title_en}</Text>
                                        </div>
                                        <span className={style.connectLine}/>
                                    </div>
                                    <p className={style.subDescription}><Text>{props.language ? data?.description : data?.description_en}</Text></p>
                                </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default ServiceOrient