import style from "./styles/software-development.module.scss"
import Title from "../../../../../components/Title/Title"
import Text from "../../../../../components/Intents/Intent"
const ServiceDevelopment = (props: any) => {
  const renderDevelopment = (item: any, index: number) => {
    return (
      <div style={{ marginBottom: index ? "0" : "14.1vh" }}>
        <div className={style.containerTop}>
          <Title title={props.language ? item.title : item.title_en} />
          <div className={style.mainDescription}>
            <Text>
              {props.language ? item.description : item.description_en}
            </Text>
          </div>
        </div>
        <div className={style.containerBottom}>
          {item?.softwareDevelopmentList &&
            item?.softwareDevelopmentList?.length > 0 &&
            item?.softwareDevelopmentList?.map(
              (content: any, index: number) => {
                return (
                  <div className={style.item} key={index}>
                    <div className={style.itemTitle}>
                      <Text>
                        {props.language ? content?.title : content?.title_en}
                      </Text>
                    </div>
                    <div className={style.itemContent}>
                      <Text>
                        {props.language
                          ? content?.description
                          : content?.description_en}
                      </Text>
                    </div>
                  </div>
                )
              }
            )}
        </div>
      </div>
    )
  }
  return (
    <div className={`container ${style.container}`}>
      {props?.data &&
        props?.data?.length > 0 &&
        props?.data?.map((value: any, i: number) => {
          return renderDevelopment(value, i)
        })}
    </div>
  )
}
export default ServiceDevelopment
