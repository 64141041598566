import axios from "axios"

export const getTechnologyPHP = () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/php-technology?populate=deep`)
    .then((res: any) => {
      return res?.data?.data
    })
}
export const getTechnologyNodeJS = () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/node-js-technology?populate=deep`)
    .then((res: any) => {
      return res?.data?.data
    })
}
export const getTechnologyReact = () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/react-technology?populate=deep`)
    .then((res: any) => {
      return res?.data?.data
    })
}
export const getTechnologyAngular = () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/angular-technology?populate=deep`)
    .then((res: any) => {
      return res?.data?.data
    })
}
export const getTechnologyVue = () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/vue-technology?populate=deep`)
    .then((res: any) => {
      return res?.data?.data
    })
}
export const getTechnologyFlutter = () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/flutter-technology?populate=deep`)
    .then((res: any) => {
      return res?.data?.data
    })
}
export const getTechnologyIOS = () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/angular-technology?populate=deep`)
    .then((res: any) => {
      return res?.data?.data
    })
}
export const getTechnologyAndroid = () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/android-technology?populate=deep`)
    .then((res: any) => {
      return res?.data?.data
    })
}
export const getTechnologyGolang = () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/golang-technology?populate=deep`)
    .then((res: any) => {
      return res?.data?.data
    })
}
export const getTechnologyJava = () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/java-technology?populate=deep`)
    .then((res: any) => {
      return res?.data?.data
    })
}
