import Text from "../../../../../../components/Intents/Intent"

//styles
import style from './style/augment.module.scss'

const Augment = (props: any) => {
    return (
        <div className={`container ${style.container}`}>
            <div className={style.title}><Text>{props.language ? props?.data?.title : props?.data?.title_en}</Text></div>
            <div className={style.content}><Text>{props.language ? props?.data?.description : props?.data?.description_en}</Text></div>
            <div className={style.imageContainer}>
                <div className={`background-image ${style.image}`} style={{backgroundImage: `url('${props?.data?.image?.data?.attributes?.url}')`}}/>
            </div>
        </div>
    )
}
export default Augment