import Text from "../Intents/Intent"
//styles
import style from './style/introduce.module.scss'

interface Props{
    language?: boolean;
    data?: any;
    title?: string;
    description?: string;
    image?:any
}


const Introduce = (props: Props) => {
    return (
            <div className={`container ${style.container}`}>
                {
                    props?.data &&
                    <div className={style.containerIntroduce}>
                        <div className={`${style.containerItem} ${style.containerLeft}`}>

                            <h3 className={style.title}>
                                <Text>{props?.language ? props?.data?.title : props?.data?.title_en}</Text>
                            </h3>
                            <p className={style.content}>
                                <Text>{props?.language ? props?.data?.description : props?.data?.description_en}</Text><span>{props?.data?.phone}</span>
                            </p>
                        </div>
                        <div className={`${style.containerItem} ${style.containerRight}`}>
                            <div className={`background-image ${style.image}`} style={{backgroundImage: `url('${props?.data?.image?.data?.attributes?.url}')`}}/>
                        </div>
                    </div>
                }
            </div>
    )
}
export default Introduce