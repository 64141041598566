import Title from "../../../../../components/Title/Title"
import Language from "../../../../../assets/translations/language"
import Text from "../../../../../components/Intents/Intent"
import React from "react"

//styles
import style from './style/service-get-started.module.scss'

const ServiceGetStarted = (props : any) => {
    const title = Language.service_get_started_mainTitle

    return (
        <div className={`container ${style.container}`}>
            <div className={style.containerTop}>
                <Title title={title}/>
            </div>
            <div className={style.containerBottom}>
                {
                    (props?.data?.attributes?.howToGetStarted?.howToGetStartedList?.length > 0) &&
                    props?.data?.attributes?.howToGetStarted?.howToGetStartedList?.map((service: any, index: number) => {
                        return (
                            <div className={style.subContainer} key={index}>
                                <p className={style.subTitle}><Text>{props.language ? service?.title : service?.title_en}</Text></p>
                                <p className={style.subDescription}><Text>{props.language ? service?.description :  service?.description_en}</Text></p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default ServiceGetStarted