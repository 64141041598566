const { default: axios } = require("axios")

// export const sendContact = (payload: any) => {
//     return axios.put(`${process.env.REACT_APP_BASE_URL}/service-custom-qa-and-software-testing?populate=deep`, payload).then((res : any) =>{
//         return res?.data?.data;

//     });
// }
export const getContactUs = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/contact-us-desktop?populate=deep`).then((res : any) =>{
        return res?.data?.data;
    });
}
export const sendContactUs = (payload: any) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/contact-us-desktop`, payload).then((res : any) =>{
        return res?.data?.data;
    });
}
