import React, { useEffect, useState } from "react"
import Language from "../../../assets/translations/language"
import styles from "../styles/technology.module.scss"
//images
import img from "../../../assets/images/image-php-case-study.png"
import imgService from "../../../assets/images/image-service-java-1.png"
import imgProgress from "../../../assets/images/image-augment-java.png"
import icDB from "../../../assets/icons/ic-database.svg"
import icLib from "../../../assets/icons/ic-library.svg"
import icFramework from "../../../assets/icons/ic-framework.svg"
import icTool from "../../../assets/icons/ic-tool.svg"
import { useTranslation } from "react-i18next"
import { getTechnologyVue } from "../../../Technologies/Technologies"
import { introductionAngular } from "../../../interface/introductionAngular"
import Page from "../../../components/ComponentPage/Page"
import Banner from "../../../components/Banner/Banner"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb"
import ReachOut from "../../Contact/component/ReachOut/ReachOut"

interface dataTechVue {
  attributes: any
  introductionAngular: Array<introductionAngular>
}
const TechnologyVue = () => {
  const [dataTechVue, setDataTechVue] = useState<dataTechVue>()
  const getData = () => {
    getTechnologyVue().then((result: any) => {
      setDataTechVue(result)
    })
  }
  const { i18n } = useTranslation()
  const language = i18n.language === "vi"

  useEffect(() => {
    getData()
  }, [])
  const data = {
    breadcrumb: {
      root: "Home",
      path: "Technology",
      destination: "Vue"
    },
    generalInfo: {
      title: Language.technology_python_title,
      content: Language.technology_python_content,
      url: ""
    },
    serviceData: {
      title: Language.technology_python_slider_title,
      description: Language.technology_python_data_service_content,
      services: [
        {
          title: Language.technology_python_slider_subTitle_1,
          content: Language.technology_python_slider_subContent_1,
          image_url: imgService
        },
        {
          title: Language.technology_python_slider_subTitle_2,
          content: Language.technology_python_slider_subContent_2,
          image_url: imgService
        },
        {
          title: Language.technology_python_slider_subTitle_3,
          content: Language.technology_python_slider_subContent_3,
          image_url: imgService
        }
      ]
    },
    solutionData: {
      title: Language.technology_python_slider_title,
      description: "",
      itemList: [
        {
          title: Language.technology_python_slider_subTitle_1,
          content: Language.technology_python_slider_subContent_1,
          image_url: img
        },
        {
          title: Language.technology_python_slider_subTitle_2,
          content: Language.technology_python_slider_subContent_2,
          image_url: img
        },
        {
          title: Language.technology_python_slider_subTitle_3,
          content: Language.technology_python_slider_subContent_3,
          image_url: img
        }
      ]
    },
    progressData: {
      title: Language.technology_python_augment_title,
      content: Language.technology_python_augment_content,
      image_url: imgProgress
    },
    techAndToolsData: {
      title: Language.technology_python_tool_title,
      techAndTools: [
        {
          title: Language.technology_python_tool_subTitle_1,
          content: Language.technology_python_tool_subContent_1,
          image_url: icDB
        },
        {
          title: Language.technology_python_tool_subTitle_2,
          content: Language.technology_python_tool_subContent_2,
          image_url: icLib
        },
        {
          title: Language.technology_python_tool_subTitle_3,
          content: Language.technology_python_tool_subContent_3,
          image_url: icFramework
        },
        {
          title: Language.technology_python_tool_subTitle_4,
          content: Language.technology_python_tool_subContent_4,
          image_url: icTool
        }
      ]
    }
  }

  return (
    <Page>
      <Banner />
      <Breadcrumb data={data?.breadcrumb} />
      <div className={`container ${styles.container}`}>
        <h2 className={styles.title}>
          {language
            ? dataTechVue?.attributes?.introduceVue?.title
            : dataTechVue?.attributes?.introduceVue?.title_en}
        </h2>
        <p className={styles.introduce}>
          {language
            ? dataTechVue?.attributes?.introduceVue?.description
            : dataTechVue?.attributes?.introduceVue?.description_en}
        </p>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechVue?.attributes?.introduceVue?.image?.data?.attributes?.url
          }
        />

        <h2 className={styles.title}>
          {language
            ? dataTechVue?.attributes?.introduceDesVue?.title
            : dataTechVue?.attributes?.introduceDesVue?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechVue?.attributes?.introduceDesVue?.image?.data?.attributes
              ?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechVue?.attributes?.introduceDesVue?.description
            : dataTechVue?.attributes?.introduceDesVue?.description_en}
        </p>

        <h2 className={styles.title}>
          {language
            ? dataTechVue?.attributes?.whatVue?.title
            : dataTechVue?.attributes?.whatVue?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={dataTechVue?.attributes?.whatVue?.image?.data?.attributes?.url}
        />
        <p className={styles.introduce}>
          {language
            ? dataTechVue?.attributes?.whatVue?.description
            : dataTechVue?.attributes?.whatVue?.description_en}
        </p>

        <h2 className={styles.title}>
          {language
            ? dataTechVue?.attributes?.benefitVue?.title
            : dataTechVue?.attributes?.benefitVue?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechVue?.attributes?.benefitVue?.image?.data?.attributes?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechVue?.attributes?.benefitVue?.description
            : dataTechVue?.attributes?.benefitVue?.description_en}
        </p>
        {dataTechVue?.attributes?.benefitVue?.benefitVueList?.length > 0 &&
          dataTechVue?.attributes?.benefitVue?.benefitVueList?.map(
            (item: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <h3 className={styles.titleAdvantage}>
                    {language ? item?.title : item?.title_en}
                  </h3>
                  <p className={styles.introduce}>
                    {language ? item?.description : item?.description_en}
                  </p>
                </React.Fragment>
              )
            }
          )}
        <p className={styles.introduce}>
          {language
            ? dataTechVue?.attributes?.outsourceGolang?.description1
            : dataTechVue?.attributes?.outsourceGolang?.description1_en}
        </p>
        <h2 className={styles.title}>
          {language
            ? dataTechVue?.attributes?.businessVue?.title
            : dataTechVue?.attributes?.businessVue?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechVue?.attributes?.businessVue?.image?.data?.attributes?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechVue?.attributes?.businessVue?.description
            : dataTechVue?.attributes?.businessVue?.description_en}
        </p>
        <h2 className={styles.title}>
          {language
            ? dataTechVue?.attributes?.priceVue?.title
            : dataTechVue?.attributes?.priceVue?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={dataTechVue?.attributes?.priceVue?.image?.data?.attributes?.url}
        />
        <p className={styles.introduce}>
          {language
            ? dataTechVue?.attributes?.priceVue?.description
            : dataTechVue?.attributes?.priceVue?.description_en}
        </p>
      </div>

      <ReachOut />
    </Page>
  )
}

export default TechnologyVue
