import style from "./style/industry-expertise.module.scss"

import Language from "../../../../../assets/translations/language"
import Text from "../../../../../components/Intents/Intent"

const IndustryExpertise = (props: any) => {
  return (
    <div className={`container`}>
      <div className={style.container}>
        <div className={style.mainTitle}>
          <Text>
            {Language[`service_soft_dev_industry_expertise_mainTitle`]}
          </Text>
        </div>
        <div className={style.mainContent}>
          <Text>
            {props.language
              ? props?.data?.description
              : props?.data?.description_en}
          </Text>
        </div>
        <div className={style.imageList}>
          {props?.data?.ourIndustryList &&
            props?.data?.ourIndustryList.length > 0 &&
            props?.data?.ourIndustryList.map((item: any, index: number) => {
              return (
                <div className={style.imageContainer} key={index}>
                  <svg
                    height="24"
                    version="1.1"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "5px" }}
                  >
                    <g transform="translate(0 -1028.4)">
                      <path
                        d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z"
                        fill="#27ae60"
                        transform="translate(0 1029.4)"
                      />
                      <path
                        d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z"
                        fill="#2ecc71"
                        transform="translate(0 1028.4)"
                      />
                      <path
                        d="m16 1037.4-6 6-2.5-2.5-2.125 2.1 2.5 2.5 2 2 0.125 0.1 8.125-8.1-2.125-2.1z"
                        fill="#27ae60"
                      />
                      <path
                        d="m16 1036.4-6 6-2.5-2.5-2.125 2.1 2.5 2.5 2 2 0.125 0.1 8.125-8.1-2.125-2.1z"
                        fill="#ecf0f1"
                      />
                    </g>
                  </svg>
                  <Text>{props.language ? item?.title : item?.title_en}</Text>
                </div>
              )
            })}
        </div>
        <div className={style.rightImage}>
          <div
            className={`background-image ${style.image}`}
            style={{
              backgroundImage: `url('${props?.data?.attributes?.technologyExpertise?.image?.data?.attributes?.url}')`
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default IndustryExpertise
