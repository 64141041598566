import Banner from "../../../../components/Banner/Banner"
import Introduce from "../../../../components/Introduce/Introduce"
import DataServiceWithPython from "./components/DataServiceWithPython/DataServiceWithPython"
import TechPythonSlider from "./components/TechPythonSlider/TechPythonSlider"
import Augment from "./components/Augment/Augment"
import TechAndTools from "./components/TechAndTools/TechAndTools"
import Page from "../../../../components/ComponentPage/Page"
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb"
import ReachOut from "../../../Contact/component/ReachOut/ReachOut"

const TechnologyComponent = (props: any) => {
  return (
    <Page>
      <Banner />
      <Breadcrumb data={props?.data?.breadcrumb} />
      <Introduce data={props?.data1} language={props?.language} />
      <DataServiceWithPython
        data={props?.data2}
        language={props?.language}
        data_list={props?.data3}
      />
      <TechPythonSlider
        data={props?.data4}
        data_list={props?.data5}
        language={props?.language}
      />
      <Augment data={props?.data6} language={props?.language} />
      <TechAndTools
        data={props?.data7}
        data_title={props?.data8}
        language={props?.language}
      />
      <ReachOut />
    </Page>
  )
}
export default TechnologyComponent
