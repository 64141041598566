import Language from "../../../../../../assets/translations/language"
import Text from "../../../../../../components/Intents/Intent"
import Title from "../../../../../../components/Title/Title"
import React from "react"

//styles
import style from './style/data-service-with-python.module.scss'

//icons
import icArrow from '../../../../../../assets/icons/ic-red-arrow.svg'

const DataServiceWithPython = (props: any) => {
    const {data} = props
    return (
        <div className={`container ${style.container}`}>
            <div className={style.containerTop}>
                <Title title={props.language ? data?.title_main : data?.title_main_en}/>
                <div className={style.mainDescription}>
                    <Text>{props.language ? data?.description : data?.description_en}</Text>
                </div>
            </div>
            <div className={style.containerBottom}>
                {
                    (props?.data_list && props?.data_list?.length > 0) &&
                    props?.data_list?.map((item: any, index: number) => {
                        return (
                            <div className={style.itemContainer} key={`item_${index}`}>
                                <div className={style.itemInfo}>
                                    <div className={style.item}>
                                        <div className={style.itemTitle}>
                                            <Text>{props.language ? item?.title : item?.title_en}</Text>
                                        </div>
                                        <div className={style.itemContent}>
                                            <Text>{props.language ? item?.description : item?.description_en}</Text>
                                        </div>
                                    </div>
                                    <div className={style.btnReadMore}>
                                        <Text>{Language.read_more}</Text> <img src={icArrow} alt={'icon arrow'}/>
                                    </div>
                                </div>
                                <div className={style.imageContainer}>
                                    <div className={`background-image ${style.image}`}
                                         style={{backgroundImage: `url('${item?.image?.data?.attributes?.url}')`}}/>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default DataServiceWithPython