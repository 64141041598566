import React, {  useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"

//styles
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"
 import styles from  "./styles/slider.module.scss"

// import required modules
import SwiperCore, {FreeMode, Navigation, Pagination, Thumbs} from "swiper"

export const Slider = (props: any) => {
    const [thumbsSwiper] = useState<SwiperCore>()


    return (
        <>
            <Swiper loop={true}
                    spaceBetween={10}
                    thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                    modules={[FreeMode, Thumbs,Navigation,Pagination]}
                    initialSlide={props?.index || 0}
                    className="mySwiperPopup"
            >
                {
                    props?.images?.map((data: any, index: number) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className={`background-image ${styles.imgPopUp}`} style={{backgroundImage:`url('${data?.image?.data?.attributes?.url}')`}}/>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            {/*<Swiper onSwiper={setThumbsSwiper}
                    spaceBetween={5}
                    slidesPerView={'auto'}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiperItem"
            >
                {
                    props?.images?.map((image: any, index: number) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className={`background-image ${styles.imgPopUp}`} style={{backgroundImage:`url("${image?.image?.data?.attributes?.url}")`}}/>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>*/}
        </>
    )
}
