import { useState } from "react"
import Title from "../../../../components/Title/Title"
import ShowCaseItem from "./component/ShowCaseItem/ShowCaseItem"
import Language from "../../../../assets/translations/language"
import Text from "../../../../components/Intents/Intent"
import PopupDesktop from "../PopupDesktop/PopupDesktop"

//styles
import styles from "./styles/show-case-view.module.scss"

//icons
import { useTranslation } from "react-i18next"

const ShowCaseView = (props: any) => {
  const [filter, setFilter] = useState("Xem tất cả")
  const [showPopup, setShowPopup] = useState(false)
  const [itemDetail, setItemDetail] = useState({})
  const { i18n } = useTranslation()
  const language = i18n.language === "vi"

  const handleClosePopup = () => {
    document.body.style.overflow = "unset"
    setShowPopup(false)
    setItemDetail({})
  }
  const handleOpenPopup = (item: any, index: number) => {
    document.body.style.overflow = "hidden"
    setShowPopup(true)
    setItemDetail({ ...item, index: index })
  }

  return (
    <>
      <div className={`container ${styles.containerShowcase}`}>
        <Title title={Language.showcase} className={styles.title} />
        <div className={styles.showcaseMain}>
          <div className={styles.action}>
            {props?.data?.attributes?.showCaseView?.buttonList?.length > 0 &&
              props?.data?.attributes?.showCaseView?.buttonList?.map(
                (tab: any, index: number) => {
                  return (
                    <button
                      key={index}
                      className={`${styles.btn} ${
                        filter === tab.title_button ? styles.activeBtn : ""
                      }`}
                      onClick={() => {
                        setFilter(tab.title_button)
                      }}
                    >
                      <Text>
                        {props.language
                          ? tab?.title_button
                          : tab?.title_button_en}
                      </Text>
                    </button>
                  )
                }
              )}
          </div>
          <div className={styles.grid}>
            {props?.data?.attributes?.showCaseView?.showCaseItem
              ?.filter((item: any) =>
                filter === "Xem tất cả" ? item : item?.categories === filter
              )
              .map((value: any, index: number) => {
                return (
                  <ShowCaseItem
                    key={index}
                    data={value}
                    handleOpenPopup={() => handleOpenPopup(value, index)}
                  />
                )
              })}
          </div>
        </div>
      </div>
      {showPopup && (
        <PopupDesktop
          data={props?.data?.attributes?.showCaseView?.showCaseItem?.filter(
            (item: any) =>
              filter === "Xem tất cả" ? item : item?.categories === filter
          )}
          item={itemDetail}
          handleClosePopup={handleClosePopup}
          language={language}
        />
      )}
    </>
  )
}
export default ShowCaseView
