import Banner from "../../../components/Banner/Banner"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb"
import React, { useEffect, useState } from "react"
import Page from "../../../components/ComponentPage/Page"
import styles from "../styles/technology.module.scss"
//images & icons
import { useTranslation } from "react-i18next"
import { introductionNodeJS } from "../../../interface/introductionNodeJS"
import { getTechnologyNodeJS } from "../../../Technologies/Technologies"
import ReachOut from "../../Contact/component/ReachOut/ReachOut"

interface dataTechNodeJS {
  attributes: any
  introductionPHP: Array<introductionNodeJS>
}
const TechnologyNodeJS = () => {
  const [dataTechNodeJS, setDataTechNodeJS] = useState<dataTechNodeJS>()
  const getData = () => {
    getTechnologyNodeJS().then((result: any) => {
      setDataTechNodeJS(result)
    })
  }
  const { i18n } = useTranslation()
  const language = i18n.language === "vi"

  useEffect(() => {
    getData()
  }, [])
  // console.log(dataTechNodeJS)

  const breadcrumb = {
    root: "Home",
    path: "Technology",
    destination: "NodeJS"
  }

  return (
    <Page>
      <Banner />
      <Breadcrumb data={breadcrumb} />
      <div className={`container ${styles.container}`}>
        <h2 className={styles.title}>
          {language
            ? dataTechNodeJS?.attributes?.introductionNodeJS?.title
            : dataTechNodeJS?.attributes?.introductionNodeJS?.title_en}
        </h2>
        <p className={styles.introduce}>
          {language
            ? dataTechNodeJS?.attributes?.introductionNodeJS?.description
            : dataTechNodeJS?.attributes?.introductionNodeJS?.description_en}
        </p>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechNodeJS?.attributes?.introductionNodeJS?.image?.data
              ?.attributes?.url
          }
        />
        <h2 className={styles.title}>
          {language
            ? dataTechNodeJS?.attributes?.nodeDoWork?.title
            : dataTechNodeJS?.attributes?.nodeDoWork?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechNodeJS?.attributes?.nodeDoWork?.image?.data?.attributes?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechNodeJS?.attributes?.nodeDoWork?.description
            : dataTechNodeJS?.attributes?.nodeDoWork?.description_en}
        </p>
        <h2 className={styles.title}>
          {language
            ? dataTechNodeJS?.attributes?.benifitsNodeJS?.title
            : dataTechNodeJS?.attributes?.benifitsNodeJS?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechNodeJS?.attributes?.benifitsNodeJS?.image?.data?.attributes
              ?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechNodeJS?.attributes?.benifitsNodeJS?.description
            : dataTechNodeJS?.attributes?.benifitsNodeJS?.description_en}
        </p>
        {dataTechNodeJS?.attributes?.benifitsNodeJS?.benifitsNodeJSList
          ?.length > 0 &&
          dataTechNodeJS?.attributes?.benifitsNodeJS?.benifitsNodeJSList?.map(
            (item: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <h3>{language ? item?.title : item?.title_en}</h3>
                  <p className={styles.introduce}>
                    {language ? item?.description : item?.description_en}
                  </p>
                </React.Fragment>
              )
            }
          )}
        <h2 className={styles.title}>
          {language
            ? dataTechNodeJS?.attributes?.outsourcingNodeJs?.title
            : dataTechNodeJS?.attributes?.outsourcingNodeJs?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechNodeJS?.attributes?.outsourcingNodeJs?.image?.data
              ?.attributes?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechNodeJS?.attributes?.outsourcingNodeJs?.description
            : dataTechNodeJS?.attributes?.outsourcingNodeJs?.description_en}
        </p>

        {dataTechNodeJS?.attributes?.outsourcingNodeJs?.outsourcingNodeJsList
          ?.length > 0 &&
          dataTechNodeJS?.attributes?.outsourcingNodeJs?.outsourcingNodeJsList?.map(
            (item: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <h3>{language ? item?.title : item?.title_en}</h3>
                  <p className={styles.introduce}>
                    {language ? item?.description : item?.description_en}
                  </p>
                </React.Fragment>
              )
            }
          )}
        <h2 className={styles.title}>
          {language
            ? dataTechNodeJS?.attributes?.priceNodeJS?.title
            : dataTechNodeJS?.attributes?.priceNodeJS?.title_en}
        </h2>
        <img
          alt=""
          className={styles.image}
          src={
            dataTechNodeJS?.attributes?.priceNodeJS?.image?.data?.attributes
              ?.url
          }
        />
        <p className={styles.introduce}>
          {language
            ? dataTechNodeJS?.attributes?.priceNodeJS?.description
            : dataTechNodeJS?.attributes?.priceNodeJS?.description_en}
        </p>
      </div>

      <ReachOut />
    </Page>
  )
}
export default TechnologyNodeJS
