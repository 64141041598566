import React, {useState, useEffect} from 'react'
import Header from "./Header/Header"
import Footer from './Footer/Footer'
import { getFooter } from '../../service/service'
import { useTranslation } from 'react-i18next'


const Page = (props: any) => {
    const[dataFooter, setDataFooter] = useState()
    const getData =  () => {
        getFooter().then((result: any) =>{
            setDataFooter(result);
        });
   }
   const {i18n} = useTranslation()
   const language = i18n.language === "vi";
   
   useEffect(() => {
       getData();
   }, [])
//    console.log(dataFooter);
   
    return(
        <>
            <Header/>
            {props?.children}
            <Footer 
                data={dataFooter} 
                language={language}
            />
        </>
    )
}
export default Page