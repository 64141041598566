import React, {useState} from "react"
import style from './styles/get-started.module.scss'
import Language from "../../../../../assets/translations/language"
import Title from "../../../../../components/Title/Title"
import Text from "../../../../../components/Intents/Intent"
import icContent from "../../../../../assets/icons/ic-content.svg"
import icContentBlack from "../../../../../assets/icons/ic-content-black.svg"

const GetStarted =(props:any)=>{
    const title = Language[`service_soft_dev_get_started_mainTitle`]
    const [isActiveIndex, setActiveIndex] = useState(0)
    return(
        <div className={`container ${style.container}`}>
            <div className={style.containerTop}>
                <Title title={title} />
                <div className={style.mainDescription}><Text>{props.language ? props?.data?.description :props?.data?.description_en }</Text></div>
            </div>
            <div className={style.containerBottom}>
                {
                    props?.data?.contentSoftDevList && props?.data?.contentSoftDevList?.length > 0 &&
                    props?.data?.contentSoftDevList?.map((data: any, index: number) => {
                        return (
                            <div className={`${style.subContainer} `} key={`content_${index}`} onClick={() => {setActiveIndex(index)}}>
                                <div className={`${style.subTitleContainer} ${index === isActiveIndex ? style.activeTitle : ''}`}>
                                    <img src={index === isActiveIndex ? icContent : icContentBlack} alt={''}/>
                                    <div className={style.subTitle}>
                                        <Text>{props.language ? data?.title : data?.title_en }</Text>
                                    </div>
                                </div>
                                <span className={`${style.subDescription} ${index === isActiveIndex ? style.activeContent : ''}`}><Text>{props.language ? data?.description : data?.description_en }</Text></span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default  GetStarted;