import TechnologyComponent from "../components/TechnologyComponent/TechnologyComponent"
import { useEffect, useState } from "react"

//icons & images
import { useTranslation } from "react-i18next"
import { getTechnologyAngular } from "../../../Technologies/Technologies"
import { introductionAngular } from "../../../interface/introductionAngular"

interface dataTechAngular{
    attributes: any;
    introductionAngular: Array<introductionAngular>

}
const TechnologyAngular = () => {
    const [dataTechAngular, setDataTechAngular] = useState<dataTechAngular>();
    const getData = () => {
        getTechnologyAngular().then((result : any)=>{
            setDataTechAngular(result);

        });
    }
    const {i18n} = useTranslation();
    const language = i18n.language === "vi";
    
    useEffect(() => {
        getData();
    }, [])

    return <TechnologyComponent 
            data1={dataTechAngular?.attributes?.introductionAngular}
            data2={dataTechAngular?.attributes?.ourDataServiceAngular}
            data3={dataTechAngular?.attributes?.ourDataServiceAngular?.ourDataServiceList}
            data4={dataTechAngular?.attributes?.techSliderAngular}
            data5={dataTechAngular?.attributes?.techSliderAngular?.techSliderList}
            data6={dataTechAngular?.attributes?.augmentAngular}
            data7={dataTechAngular?.attributes?.techToolAngular?.techToolList}
            data8={dataTechAngular?.attributes?.techToolAngular}
            language={language}
    />
}

export default TechnologyAngular