const Language = {
    //language
    vi_language_text: "vi_language_text",
    en_language_text: "en_language_text",

    //label
    address_label: 'address_label',
    contact_label: 'contact_label',

    read_more: "read_more",
    name: "name",
    hello: "hello",
    //header
    menu_whoweserve: "menu_whoweserve",
    menu_services: "menu_services",
    menu_technology: "menu_technology",
    menu_technology_link: "menu_technology_link",
    menu_pattern: "menu_pattern",
    menu_contact_us: "menu_contact_us",
    home_whatwedo: "home_whatwedo",
    home_production: "home_production",
    //footer
    footer_our_services_title: "footer_our_services_title",
    footer_our_expertise_title: "footer_our_expertise_title",
    footer_advanced_technologies_title: "footer_advanced_technologies_title",
    footer_contact_us_title: "footer_contact_us_title",
    footer_mainDescription: "footer_mainDescription",

    footer_our_services_title_item_1: "footer_our_services_title_item_1",
    footer_our_services_title_item_2: "footer_our_services_title_item_2",
    footer_our_services_title_item_3: "footer_our_services_title_item_3",
    footer_our_services_title_item_4: "footer_our_services_title_item_4",

    footer_our_expertise_title_item_1: "footer_our_expertise_title_item_1",
    footer_our_expertise_title_item_2: "footer_our_expertise_title_item_2",
    footer_our_expertise_title_item_3: "footer_our_expertise_title_item_3",
    footer_our_expertise_title_item_4: "footer_our_expertise_title_item_4",

    footer_advanced_technologies_title_item_1: "footer_advanced_technologies_title_item_1",
    footer_advanced_technologies_title_item_2: "footer_advanced_technologies_title_item_2",
    footer_advanced_technologies_title_item_3: "footer_advanced_technologies_title_item_3",
    footer_advanced_technologies_title_item_4: "footer_advanced_technologies_title_item_4",
    footer_advanced_technologies_title_item_5: "footer_advanced_technologies_title_item_5",

    footer_contact_us_address: "footer_contact_us_address",
    // modal
    header_backendTech: "header_backendTech",
    header_frontendTech: "header_frontendTech",
    header_dataScientist: "header_dataScientist",
    //home
    //page We are orient software

    home_title_home: "home_title_home",
    home_content_home: "home_content_home",
    header_showcase: "header_showcase",
    //page Our expertise
    home_ourexpertise_title: "home_ourexpertise_title",
    home_our_expertise_subTitle_1: "home_our_expertise_subTitle_1",
    home_our_expertise_subContent_1: "home_our_expertise_subContent_1",
    home_our_expertise_subTitle_2: "home_our_expertise_subTitle_2",
    home_our_expertise_subContent_2: "home_our_expertise_subContent_2",
    home_our_expertise_subTitle_3: "home_our_expertise_subTitle_3",
    home_our_expertise_subContent_3: "home_our_expertise_subContent_3",
    //what we do
    home_title_whatwedo: "home_title_whatwedo",
    home_whatwedo_mainDescription: "home_whatwedo_mainDescription",
    home_whatwedo_subTitle_1: "home_whatwedo_subTitle_1",
    home_whatwedo_subContent_1: "home_whatwedo_subContent_1",
    home_whatwedo_subTitle_2: "home_whatwedo_subTitle_2",
    home_whatwedo_subContent_2: "home_whatwedo_subContent_2",
    home_whatwedo_subTitle_3: "home_whatwedo_subTitle_3",
    home_whatwedo_subContent_3: "home_whatwedo_subContent_3",
    home_whatwedo_subTitle_4: "home_whatwedo_subTitle_4",
    home_whatwedo_subContent_4: "home_whatwedo_subContent_4",

    //Why choose us
    home_why_choose_us_title: "home_why_choose_us_title",
    home_why_choose_us_mainDescription_1: "home_why_choose_us_mainDescription_1",
    home_why_choose_us_mainDescription_2: "home_why_choose_us_mainDescription_2",
    home_why_choose_us_subContent_1: "home_why_choose_us_subContent_1",
    home_why_choose_us_subContent_2: "home_why_choose_us_subContent_2",
    home_why_choose_us_subContent_3: "home_why_choose_us_subContent_3",
    home_why_choose_us_subContent_4: "home_why_choose_us_subContent_4",
    home_why_choose_us_subContent_5: "home_why_choose_us_subContent_5",
    home_why_choose_us_subContent_6: "home_why_choose_us_subContent_6",
    home_why_choose_us_subContent_7: "home_why_choose_us_subContent_7",

    //Our client
    home_our_client_title: "home_our_client_title",
    //Partners
    home_our_partners_title: "home_our_partners_title",
    //service - web dev
    service_general_info_title: "service_general_info_title",
    service_general_info_content: "service_general_info_content",
    //our solution
    service_solution_title: "service_solution_title",
    service_solution_mainDescription: "service_solution_mainDescription",
    service_solution_subTitle_1: "service_solution_subTitle_1",
    service_solution_subTitle_2: "service_solution_subTitle_2",
    service_solution_subDescription_1: "service_solution_subDescription_1",
    service_solution_subDescription_2: "service_solution_subDescription_2",

    //orient
    service_orient_mainTitle: "service_orient_mainTitle",
    service_orient_mainDescription: "service_orient_mainDescription",
    service_orient_item_1_title: "service_orient_item_1_title",
    service_orient_item_1_content: "service_orient_item_1_content",
    service_orient_item_2_title: "service_orient_item_2_title",
    service_orient_item_2_content: "service_orient_item_2_content",
    service_orient_item_3_title: "service_orient_item_3_title",
    service_orient_item_3_content: "service_orient_item_3_content",

    //get started
    service_get_started_mainTitle: "service_get_started_mainTitle",
    service_get_started_subTitle_1: "service_get_started_subTitle_1",
    service_get_started_subContent_1: "service_get_started_subContent_1",
    service_get_started_subTitle_2: "service_get_started_subTitle_2",
    service_get_started_subContent_2: "service_get_started_subContent_2",


    //
    services_soft_dev_introduce_title:"services_soft_dev_introduce_title",
    services_soft_dev_introduce_content:"services_soft_dev_introduce_content",

    services_soft_dev_content_item_1_title:"services_soft_dev_content_item_1_title",
    services_soft_dev_content_item_2_title:"services_soft_dev_content_item_2_title",
    services_soft_dev_content_item_3_title:"services_soft_dev_content_item_3_title",
    services_soft_dev_content_item_4_title:"services_soft_dev_content_item_4_title",
    services_soft_dev_content_item_5_title:"services_soft_dev_content_item_5_title",
    services_soft_dev_content_item_6_title:"services_soft_dev_content_item_6_title",
    services_soft_dev_content_item_1_content:"services_soft_dev_content_item_1_content",
    services_soft_dev_content_item_2_content:"services_soft_dev_content_item_2_content",
    services_soft_dev_content_item_3_content:"services_soft_dev_content_item_3_content",
    services_soft_dev_content_item_4_content:"services_soft_dev_content_item_4_content",
    services_soft_dev_content_item_5_content:"services_soft_dev_content_item_5_content",
    services_soft_dev_content_item_6_content:"services_soft_dev_content_item_6_content",

    //what we do service
    service_soft_dev_what_we_do_mainTitle:"service_soft_dev_what_we_do_mainTitle",

    service_soft_dev_what_we_do_item_1_title:"service_soft_dev_what_we_do_item_1_title",
    service_soft_dev_what_we_do_item_2_title:"service_soft_dev_what_we_do_item_2_title",
    service_soft_dev_what_we_do_item_3_title:"service_soft_dev_what_we_do_item_3_title",
    service_soft_dev_what_we_do_item_4_title:"service_soft_dev_what_we_do_item_4_title",
    service_soft_dev_what_we_do_item_5_title:"service_soft_dev_what_we_do_item_5_title",
    service_soft_dev_what_we_do_item_6_title:"service_soft_dev_what_we_do_item_6_title",

    service_soft_dev_what_we_do_item_1_content:"service_soft_dev_what_we_do_item_1_content",
    service_soft_dev_what_we_do_item_2_content:"service_soft_dev_what_we_do_item_2_content",
    service_soft_dev_what_we_do_item_3_content:"service_soft_dev_what_we_do_item_3_content",
    service_soft_dev_what_we_do_item_4_content:"service_soft_dev_what_we_do_item_4_content",
    service_soft_dev_what_we_do_item_5_content:"service_soft_dev_what_we_do_item_5_content",
    service_soft_dev_what_we_do_item_6_content:"service_soft_dev_what_we_do_item_6_content",

    //activities
    service_activities_mainTitle: "service_activities_mainTitle",
    service_activities_navTitle_1: "service_activities_navTitle_1",
    service_activities_navContent_1: "service_activities_navContent_1",
    service_activities_navImgURL_1: "service_activities_navImgURL_1",
    service_activities_navTitle_2: "service_activities_navTitle_2",
    service_activities_navContent_2: "service_activities_navContent_2",
    service_activities_navImgURL_2: "service_activities_navImgURL_2",
    service_activities_navTitle_3: "service_activities_navTitle_3",
    service_activities_navContent_3: "service_activities_navContent_3",
    service_activities_navImgURL_3: "service_activities_navImgURL_3",
    service_activities_navTitle_4: "service_activities_navTitle_4",
    service_activities_navContent_4: "service_activities_navContent_4",
    service_activities_navImgURL_4: "service_activities_navImgURL_4",
    service_activities_navTitle_5: "service_activities_navTitle_5",
    service_activities_navContent_5: "service_activities_navContent_5",
    service_activities_navImgURL_5: "service_activities_navImgURL_5",
    service_activities_navTitle_6: "service_activities_navTitle_6",
    service_activities_navContent_6: "service_activities_navContent_6",
    service_activities_navImgURL_6: "service_activities_navImgURL_6",
    service_activities_navTitle_7: "service_activities_navTitle_7",
    service_activities_navContent_7: "service_activities_navContent_7",
    service_activities_navImgURL_7: "service_activities_navImgURL_7",

    //service - app dev
    //why orient
    service_orient_app_mainTitle: "service_orient_app_mainTitle",
    service_orient_app_mainDescription: "service_orient_app_mainDescription",
    service_soft_dev_software_solution_mainTitle: "service_soft_dev_software_solution_mainTitle",
    service_soft_dev_get_started_mainTitle: "service_soft_dev_get_started_mainTitle",
    service_soft_dev_industry_expertise_mainTitle: "service_soft_dev_industry_expertise_mainTitle",
    service_soft_dev_advantages_mainTitle: "service_soft_dev_advantages_mainTitle",

    service_orient_app_subTitle_1: "service_orient_app_subTitle_1",
    service_orient_app_subContent_1: "service_orient_app_subContent_1",
    service_orient_app_subTitle_2: "service_orient_app_subTitle_2",
    service_orient_app_subContent_2: "service_orient_app_subContent_2",
    service_orient_app_subTitle_3: "service_orient_app_subTitle_3",
    service_orient_app_subContent_3: "service_orient_app_subContent_3",
    service_orient_app_subTitle_4: "service_orient_app_subTitle_4",
    service_orient_app_subContent_4: "service_orient_app_subContent_4",
    service_activities_app_mainTitle: "service_activities_app_mainTitle",
    service_activities_app_navTitle_1: "service_activities_app_navTitle_1",
    service_activities_app_navContent_1: "service_activities_app_navContent_1",
    service_activities_app_navImgURL_1: "service_activities_app_navImgURL_1",
    service_activities_app_navTitle_2: "service_activities_app_navTitle_2",
    service_activities_app_navContent_2: "service_activities_app_navContent_2",
    service_activities_app_navImgURL_2: "service_activities_app_navImgURL_2",
    service_activities_app_navTitle_3: "service_activities_app_navTitle_3",
    service_activities_app_navContent_3: "service_activities_app_navContent_3",
    service_activities_app_navImgURL_3: "service_activities_app_navImgURL_3",
    service_activities_app_navTitle_4: "service_activities_app_navTitle_4",
    service_activities_app_navContent_4: "service_activities_app_navContent_4",
    service_activities_app_navImgURL_4: "service_activities_app_navImgURL_4",
    service_activities_app_navTitle_5: "service_activities_app_navTitle_5",
    service_activities_app_navContent_5: "service_activities_app_navContent_5",
    service_activities_app_navImgURL_5: "service_activities_app_navImgURL_5",
    service_activities_app_navTitle_6: "service_activities_app_navTitle_6",
    service_activities_app_navContent_6: "service_activities_app_navContent_6",
    service_activities_app_navImgURL_6: "service_activities_app_navImgURL_6",

    //Technology - python
    //Introduce
    technology_python_title: "technology_python_title",
    technology_python_content: "technology_python_content",
    //Data Service
    technology_python_data_service_title: "technology_python_data_service_title",
    technology_python_data_service_content: "technology_python_data_service_content",

    technology_python_data_service_subTitle_1: "technology_python_data_service_subTitle_1",
    technology_python_data_service_subContent_1: "technology_python_data_service_subContent_1",
    technology_python_data_service_imgURL_1: "technology_python_data_service_imgURL_1",
    technology_python_data_service_subTitle_2: "technology_python_data_service_subTitle_2",
    technology_python_data_service_subContent_2: "technology_python_data_service_subContent_2",
    technology_python_data_service_imgURL_2: "technology_python_data_service_imgURL_2",
    technology_python_data_service_subTitle_3: "technology_python_data_service_subTitle_3",
    technology_python_data_service_subContent_3: "technology_python_data_service_subContent_3",
    technology_python_data_service_imgURL_3: "technology_python_data_service_imgURL_3",

    // Slider
    technology_python_slider_title: "technology_python_slider_title",
    technology_python_slider_subTitle_1: "technology_python_slider_subTitle_1",
    technology_python_slider_subContent_1: "technology_python_slider_subContent_1",
    technology_python_slider_imgURL_1: "technology_python_slider_imgURL_1",
    technology_python_slider_subTitle_2: "technology_python_slider_subTitle_2",
    technology_python_slider_subContent_2: "technology_python_slider_subContent_2",
    technology_python_slider_imgURL_2: "technology_python_slider_imgURL_2",
    technology_python_slider_subTitle_3: "technology_python_slider_subTitle_3",
    technology_python_slider_subContent_3: "technology_python_slider_subContent_3",
    technology_python_slider_imgURL_3: "technology_python_slider_imgURL_3",

    // Augment
    technology_python_augment_title: "technology_python_augment_title",
    technology_python_augment_content: "technology_python_augment_content",
    technology_python_augment_imgURL: "technology_python_augment_imgURL",

    //Tech & Tools
    technology_python_tool_title: "technology_python_tool_title",
    technology_python_tool_subTitle_1: "technology_python_tool_subTitle_1",
    technology_python_tool_subContent_1: "technology_python_tool_subContent_1",
    technology_python_tool_subTitle_2: "technology_python_tool_subTitle_2",
    technology_python_tool_subContent_2: "technology_python_tool_subContent_2",
    technology_python_tool_subTitle_3: "technology_python_tool_subTitle_3",
    technology_python_tool_subContent_3: "technology_python_tool_subContent_3",
    technology_python_tool_subTitle_4: "technology_python_tool_subTitle_4",
    technology_python_tool_subContent_4: "technology_python_tool_subContent_4",

    // Service - soft dev
    //Devops
    service_soft_dev_devops_mainTitle: "service_soft_dev_devops_mainTitle",
    service_soft_dev_devops_mainDescription: "service_soft_dev_devops_mainDescription",
    service_soft_dev_devops_itemTitle_1: "service_soft_dev_devops_itemTitle_1",
    service_soft_dev_devops_itemTitle_2: "service_soft_dev_devops_itemTitle_2",
    service_soft_dev_devops_itemContent_1: "service_soft_dev_devops_itemContent_1",
    service_soft_dev_devops_itemContent_2: "service_soft_dev_devops_itemContent_2",

    //Tech Expertise
    service_soft_dev_tech_expertise_mainTitle: "service_soft_dev_tech_expertise_mainTitle",
    service_soft_dev_tech_expertise_mainContent: "service_soft_dev_tech_expertise_mainContent",
    service_ui_ux_introduce_title: "service_ui_ux_introduce_title",
    service_ui_ux_introduce_content: "service_ui_ux_introduce_content",
    service_ui_ux_introduce_imageURL: "service_ui_ux_introduce_imageURL",
    service_ui_ux_collaboration_title: "service_ui_ux_collaboration_title",
    service_ui_ux_collaboration_content_1: "service_ui_ux_collaboration_content_1",
    service_ui_ux_collaboration_content_2: "service_ui_ux_collaboration_content_2",
    service_ui_ux_collaboration_content_3: "service_ui_ux_collaboration_content_3",

    //How do we work
    service_ui_ux_how_work_title: "service_ui_ux_how_work_title",
    service_ui_ux_how_work_description: "service_ui_ux_how_work_description",
    service_ui_ux_how_work_mainImageURL: "service_ui_ux_how_work_mainImageURL",

    service_ui_ux_how_work_subTitle_1: "service_ui_ux_how_work_subTitle_1",
    service_ui_ux_how_work_subContent_1: "service_ui_ux_how_work_subContent_1",
    service_ui_ux_how_work_subTitle_2: "service_ui_ux_how_work_subTitle_2",
    service_ui_ux_how_work_subContent_2: "service_ui_ux_how_work_subContent_2",
    service_ui_ux_how_work_subTitle_3: "service_ui_ux_how_work_subTitle_3",
    service_ui_ux_how_work_subContent_3: "service_ui_ux_how_work_subContent_3",
    service_ui_ux_how_work_subTitle_4: "service_ui_ux_how_work_subTitle_4",
    service_ui_ux_how_work_subContent_4: "service_ui_ux_how_work_subContent_4",

    //Our UI design
    service_ui_design_our_ui_design_title: "service_ui_design_our_ui_design_title",
    service_ui_design_our_ui_design_description: "service_ui_design_our_ui_design_description",
    service_ui_design_our_ui_design_subText: "service_ui_design_our_ui_design_subText",
    service_ui_design_our_ui_design_item_title_1: "service_ui_design_our_ui_design_item_title_1",
    service_ui_design_our_ui_design_item_content_1: "service_ui_design_our_ui_design_item_content_1",
    service_ui_design_our_ui_design_item_title_2: "service_ui_design_our_ui_design_item_title_2",
    service_ui_design_our_ui_design_item_content_2: "service_ui_design_our_ui_design_item_content_2",
    service_ui_design_our_ui_design_item_title_3: "service_ui_design_our_ui_design_item_title_3",
    service_ui_design_our_ui_design_item_content_3: "service_ui_design_our_ui_design_item_content_3",
    service_ui_design_our_ui_design_item_title_4: "service_ui_design_our_ui_design_item_title_4",
    service_ui_design_our_ui_design_item_content_4: "service_ui_design_our_ui_design_item_content_4",
    service_ui_design_our_ui_design_item_title_5: "service_ui_design_our_ui_design_item_title_5",
    service_ui_design_our_ui_design_item_content_5: "service_ui_design_our_ui_design_item_content_5",
    service_ui_design_our_ui_design_item_title_6: "service_ui_design_our_ui_design_item_title_6",
    service_ui_design_our_ui_design_item_content_6: "service_ui_design_our_ui_design_item_content_6",

    //Customer satisfaction
    service_ui_ux_customer_satisfaction_title: "service_ui_ux_customer_satisfaction_title",
    service_ui_ux_customer_satisfaction_content_1: "service_ui_ux_customer_satisfaction_content_1",
    service_ui_ux_customer_satisfaction_content_2: "service_ui_ux_customer_satisfaction_content_2",



    //showcase
    showcase:"showcase",
    showcase_action_view_all:"showcase_action_view_all",
    showcase_action_it_infrastructure:"showcase_action_it_infrastructure",
    showcase_action_mobile_app:"showcase_action_mobile_app",
    showcase_action_ui_ux:"showcase_action_ui_ux",

    showcase_item_title:"showcase_item_title",
    showcase_item_note:"showcase_item_note",


    //technology first

    technology_first_frontend:"technology_first_frontend",
    technology_first_backend:"technology_first_backend",
    technology_first_framework_title:"technology_first_framework_title",
    technology_first_framework_content1:"technology_first_framework_content1",
    technology_first_framework_content2:"technology_first_framework_content2",
    technology_first_framework_content3:"technology_first_framework_content3",

    technology_first_micro_title:"technology_first_micro_title",
    technology_first_micro_content1:"technology_first_micro_content1",
    technology_first_micro_content2:"technology_first_micro_content2",



    contact_introduce_title:"contact_introduce_title",
    contact_introduce_content:"contact_introduce_content",
    contact_reach_title:"contact_reach_title",
    contact_reach_note:"contact_reach_note",

    contact_reach_form_lastname:"contact_reach_form_lastname",
    contact_reach_form_firstname:"contact_reach_form_firstname",
    contact_reach_form_email:"contact_reach_form_email",
    contact_reach_form_phone:"contact_reach_form_phone",
    contact_reach_form_btn:"contact_reach_form_btn",



    contact_within_title:"contact_within_title",
    contact_within_address:"contact_within_address",
    contact_within_contact:"contact_within_contact",
    contact_within_email:"contact_within_email",



    //Service QA & TESTING
    services_qa_testing_introduce_title: "services_qa_testing_introduce_title",
    services_qa_testing_introduce_content: "services_qa_testing_introduce_content",
    services_qa_testing_introduce_imageURL: "services_qa_testing_introduce_imageURL",

    // Why orient
    service_qa_testing_orient_title: "service_qa_testing_orient_title",

    service_qa_testing_orient_item_title_1: "service_qa_testing_orient_item_title_1",
    service_qa_testing_orient_item_content_1: "service_qa_testing_orient_item_content_1",
    service_qa_testing_orient_item_imageURL_1: "service_qa_testing_orient_item_imageURL_1",

    service_qa_testing_orient_item_title_2: "service_qa_testing_orient_item_title_2",
    service_qa_testing_orient_item_content_2: "service_qa_testing_orient_item_content_2",
    service_qa_testing_orient_item_imageURL_2: "service_qa_testing_orient_item_imageURL_2",

    service_qa_testing_orient_item_title_3: "service_qa_testing_orient_item_title_3",
    service_qa_testing_orient_item_content_3: "service_qa_testing_orient_item_content_3",
    service_qa_testing_orient_item_imageURL_3: "service_qa_testing_orient_item_imageURL_3",

    service_qa_testing_orient_item_title_4: "service_qa_testing_orient_item_title_4",
    service_qa_testing_orient_item_content_4: "service_qa_testing_orient_item_content_4",
    service_qa_testing_orient_item_imageURL_4: "service_qa_testing_orient_item_imageURL_4",

    //QA & TESTING PROCESS

    service_qa_testing_process_title: "service_qa_testing_process_title",
    service_qa_testing_process_description: "service_qa_testing_process_description",

    service_qa_testing_tabTitle_1: "service_qa_testing_tabTitle_1",
    service_qa_testing_tabContent_1: "service_qa_testing_tabContent_1",
    service_qa_testing_tabImageURL_1: "service_qa_testing_tabImageURL_1",

    service_qa_testing_tabTitle_2: "service_qa_testing_tabTitle_2",
    service_qa_testing_tabContent_2: "service_qa_testing_tabContent_2",
    service_qa_testing_tabImageURL_2: "service_qa_testing_tabImageURL_2",

    service_qa_testing_tabTitle_3: "service_qa_testing_tabTitle_3",
    service_qa_testing_tabContent_3: "service_qa_testing_tabContent_3",
    service_qa_testing_tabImageURL_3: "service_qa_testing_tabImageURL_3",

    service_qa_testing_tabTitle_4: "service_qa_testing_tabTitle_4",
    service_qa_testing_tabContent_4: "service_qa_testing_tabContent_4",
    service_qa_testing_tabImageURL_4: "service_qa_testing_tabImageURL_4",

    service_qa_testing_tabTitle_5: "service_qa_testing_tabTitle_5",
    service_qa_testing_tabContent_5: "service_qa_testing_tabContent_5",
    service_qa_testing_tabImageURL_5: "service_qa_testing_tabImageURL_5",

    service_qa_testing_tabTitle_6: "service_qa_testing_tabTitle_6",
    service_qa_testing_tabContent_6: "service_qa_testing_tabContent_6",
    service_qa_testing_tabImageURL_6: "service_qa_testing_tabImageURL_6",

    //Testing Expertise
    service_qa_testing_testing_expertise_title: "service_qa_testing_testing_expertise_title",
    
    service_qa_testing_testing_expertise_subTitle_1: "service_qa_testing_testing_expertise_subTitle_1",
    service_qa_testing_testing_expertise_subContent_1: "service_qa_testing_testing_expertise_subContent_1",
    service_qa_testing_testing_expertise_imgURL_1: "service_qa_testing_testing_expertise_imgURL_1",

    service_qa_testing_testing_expertise_subTitle_2: "service_qa_testing_testing_expertise_subTitle_2",
    service_qa_testing_testing_expertise_subContent_2: "service_qa_testing_testing_expertise_subContent_2",
    service_qa_testing_testing_expertise_imgURL_2: "service_qa_testing_testing_expertise_imgURL_2",

    service_qa_testing_testing_expertise_subTitle_3: "service_qa_testing_testing_expertise_subTitle_3",
    service_qa_testing_testing_expertise_subContent_3: "service_qa_testing_testing_expertise_subContent_3",
    service_qa_testing_testing_expertise_imgURL_3: "service_qa_testing_testing_expertise_imgURL_3",

    //Technology PHP
    technology_php_title: "technology_php_title",
    technology_php_content: "technology_php_content",

    //Our PHP Dev Service
    technology_php_dev_service_title: "technology_php_dev_service_title",
    technology_php_dev_service_mainDescription: "technology_php_dev_service_mainDescription",

    technology_php_dev_service_item_title_1: "technology_php_dev_service_item_title_1",
    technology_php_dev_service_item_content_1: "technology_php_dev_service_item_content_1",
    technology_php_dev_service_item_imageURL_1: "technology_php_dev_service_item_imageURL_1",

    technology_php_dev_service_item_title_2: "technology_php_dev_service_item_title_2",
    technology_php_dev_service_item_content_2: "technology_php_dev_service_item_content_2",
    technology_php_dev_service_item_imageURL_2: "technology_php_dev_service_item_imageURL_2",

    technology_php_dev_service_item_title_3: "technology_php_dev_service_item_title_3",
    technology_php_dev_service_item_content_3: "technology_php_dev_service_item_content_3",
    technology_php_dev_service_item_imageURL_3: "technology_php_dev_service_item_imageURL_3",

    technology_php_dev_service_item_title_4: "technology_php_dev_service_item_title_4",
    technology_php_dev_service_item_content_4: "technology_php_dev_service_item_content_4",
    technology_php_dev_service_item_imageURL_4: "technology_php_dev_service_item_imageURL_4",

    technology_php_dev_service_item_title_5: "technology_php_dev_service_item_title_5",
    technology_php_dev_service_item_content_5: "technology_php_dev_service_item_content_5",
    technology_php_dev_service_item_imageURL_5: "technology_php_dev_service_item_imageURL_5",

    technology_php_dev_service_item_title_6: "technology_php_dev_service_item_title_6",
    technology_php_dev_service_item_content_6: "technology_php_dev_service_item_content_6",
    technology_php_dev_service_item_imageURL_6: "technology_php_dev_service_item_imageURL_6",

    //Case study
    technology_php_case_study_title: "technology_php_case_study_title",
    technology_php_case_study_mainDescription: "technology_php_case_study_mainDescription",

    technology_php_case_study_subTitle: "technology_php_case_study_subTitle",
    technology_php_case_study_subContent: "technology_php_case_study_subContent",
    technology_php_case_study_imageURL: "technology_php_case_study_imageURL",
    
    //We cover all industries
    technology_php_industries_title: "technology_php_industries_title",
    technology_php_industries_mainDescription: "technology_php_industries_mainDescription",
    technology_php_industries_item_title_1: "technology_php_industries_item_title_1",
    technology_php_industries_item_title_2: "technology_php_industries_item_title_2",
    technology_php_industries_item_title_3: "technology_php_industries_item_title_3",
    technology_php_industries_item_title_4: "technology_php_industries_item_title_4",
    technology_php_industries_item_title_5: "technology_php_industries_item_title_5",
    technology_php_industries_item_title_6: "technology_php_industries_item_title_6",

    //Partners
    technology_php_partners_title: "technology_php_partners_title",

    //Technology > NodeJS
    //Introduce
    technology_node_js_introduce_title: "technology_node_js_introduce_title",
    technology_node_js_introduce_content: "technology_node_js_introduce_content",
    
    //Our Nodejs Dev Service
    technology_nodejs_dev_service_title: "technology_nodejs_dev_service_title",
    technology_nodejs_dev_service_mainDescription: "technology_nodejs_dev_service_mainDescription",

    technology_nodejs_dev_service_item_title_1: "technology_nodejs_dev_service_item_title_1",
    technology_nodejs_dev_service_item_content_1: "technology_nodejs_dev_service_item_content_1",
    technology_nodejs_dev_service_item_imageURL_1: "technology_nodejs_dev_service_item_imageURL_1",

    technology_nodejs_dev_service_item_title_2: "technology_nodejs_dev_service_item_title_2",
    technology_nodejs_dev_service_item_content_2: "technology_nodejs_dev_service_item_content_2",
    technology_nodejs_dev_service_item_imageURL_2: "technology_nodejs_dev_service_item_imageURL_2",

    technology_nodejs_dev_service_item_title_3: "technology_nodejs_dev_service_item_title_3",
    technology_nodejs_dev_service_item_content_3: "technology_nodejs_dev_service_item_content_3",
    technology_nodejs_dev_service_item_imageURL_3: "technology_nodejs_dev_service_item_imageURL_3",

    technology_nodejs_dev_service_item_title_4: "technology_nodejs_dev_service_item_title_4",
    technology_nodejs_dev_service_item_content_4: "technology_nodejs_dev_service_item_content_4",
    technology_nodejs_dev_service_item_imageURL_4: "technology_nodejs_dev_service_item_imageURL_4",

    technology_nodejs_dev_service_item_title_5: "technology_nodejs_dev_service_item_title_5",
    technology_nodejs_dev_service_item_content_5: "technology_nodejs_dev_service_item_content_5",
    technology_nodejs_dev_service_item_imageURL_5: "technology_nodejs_dev_service_item_imageURL_5",

    technology_nodejs_dev_service_item_title_6: "technology_nodejs_dev_service_item_title_6",
    technology_nodejs_dev_service_item_content_6: "technology_nodejs_dev_service_item_content_6",
    technology_nodejs_dev_service_item_imageURL_6: "technology_nodejs_dev_service_item_imageURL_6",

    //Benefits
    technology_node_js_benefits_title: "technology_node_js_benefits_title",
    technology_node_js_benefits_content_1: "technology_node_js_benefits_content_1",
    technology_node_js_benefits_content_2: "technology_node_js_benefits_content_2",

    our_expertise_title_item_1: "our_expertise_title_item_1",
    our_expertise_title_item_2: "our_expertise_title_item_2",
    our_expertise_title_item_3: "our_expertise_title_item_3",
    our_expertise_title_item_4: "our_expertise_title_item_4",
}
export default Language;

