import ServiceOrient from "./components/ServiceOrient/ServiceOrient"
import ServiceActivitiesDesktop from "../../../components/ServiceActivities/ServiceActivitiesDesktop/ServiceActivitiesDesktop"
import Banner from "../../../components/Banner/Banner"
import React, { useState , useEffect } from "react"
import Language from "../../../assets/translations/language"
import ServiceActivitiesPhone
    from "../../../components/ServiceActivities/ServiceActivitiesPhone/ServiceActivitiesPhone"
import Page from "../../../components/ComponentPage/Page"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb"

//images
import { getServiceCustomerMobile } from "../../../service/service"
import { useTranslation } from "react-i18next"
import {mobileStrategy} from "../../../interface/mobileStrategy"
import imageBanner from "../../../assets/images/image-mobile-banner.webp"

interface dataMobileDev {
    attributes: any;
    mobileStrategy: Array<mobileStrategy>;
}
const ServiceAppDev = () => {
    const [dataMobileDev, setDataMobileDev] = useState<dataMobileDev>();
    const getData =  () => {
         getServiceCustomerMobile().then((result: any) =>{
             setDataMobileDev(result);
         });
    }
    const {i18n} = useTranslation();
    const language = i18n.language === "vi";
    
    
    useEffect(() => {
        getData();
    }, [])
    
    const breadcrumb = {
        root: "Home",
        destination: "Mobile Application Development"
    }
    
    const title = Language.service_activities_app_mainTitle

    return (
        <Page>
            {
                dataMobileDev && dataMobileDev?.attributes &&
                <div>
                    <Banner imageURL={imageBanner}/>
                    <Breadcrumb data={breadcrumb}/>
                    <ServiceOrient data={dataMobileDev} language={language}/>
                    {
                        window.innerWidth > 769 &&
                        <ServiceActivitiesDesktop data={dataMobileDev?.attributes?.mobileStrategy?.mobileStrategyList } language={language} title={title}/>
                    }
                    {
                        window.innerWidth <= 769 &&
                        <ServiceActivitiesPhone data={dataMobileDev?.attributes?.mobileStrategy?.mobileStrategyList } language={language} title={title}/>
                    }
                </div>
            }
        </Page>
    )
}
export default ServiceAppDev