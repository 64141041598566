import React, {useState} from "react"
import Text from "../../../../Intents/Intent"
import Language from "../../../../../assets/translations/language"
import {Technology} from "../../../../../utils/enums/technology/technology_constant"

//styles
import styles from './styles/navigation.module.scss'

//icons & images
import icExtend from '../../../../../assets/icons/ic-extends.svg'

interface trainInfo {
    title: any;
    items: any
    path: string
}

const NavigationOnPhone = () => {

    const menus: Array<trainInfo> = [
        {
            title: Language.menu_services,
            items: [
                {
                    title: "Services",
                    contents: [
                        {
                            name: "Web Application Development",
                            slug: "/service/website-application"
                        },
                        {
                            name: "Mobile Application Development",
                            slug: "/service/mobile-application"
                        },
                        {
                            name: "UI/UX Design",
                            slug: "/service/ui-ux"
                        },
                        {
                            name: "Custom Software Development",
                            slug: "/service/soft-dev"
                        }
                    ]
                }
            ],
            path: '#'
        },
        {
            title: Language.menu_technology,
            items: [
                {
                    title: "BackEnd Technologies",
                    contents: [
                        {
                            name: Technology.golang,
                            slug: '/technology/golang'
                        },
                        {
                            name: Technology.java,
                            slug: '/technology/java'
                        },
                        {
                            name: Technology.nodejs,
                            slug: '/technology/nodeJS'
                        },
                        {
                            name: Technology.php,
                            slug: '/technology/php'
                        },
                    ]
                },
                {
                    title: "FrontEnd Technologies",
                    contents: [
                        {
                            name: Technology.angular,
                            slug: '/technology/angular'
                        },
                        {
                            name: Technology.reactjs,
                            slug: '/technology/reactjs'
                        },
                        {
                            name: Technology.vuejs,
                            slug: '/technology/vuejs'
                        },
                        {
                            name: Technology.flutter,
                            slug: '/technology/flutter'
                        },
                        {
                            name: Technology.ios,
                            slug: '/technology/ios'
                        },
                        {
                            name: Technology.android,
                            slug: '/technology/android'
                        },
                    ]
                }
            ],
            path: '#'
        },
        {
            title: Language.header_showcase,
            items: [],
            path: '/showcase'
        },
        {
            title: Language.menu_contact_us,
            items: [],
            path: '/contact-us'
        }
    ]

    const [indexActive, setIndexActive] = useState(0)

    return (
        <div className={styles.navigation}>
            {
                menus.map((value, index) => {
                    return (
                        <div key={index} className={styles.navigationItemWrapper}>
                            <div className={styles.navigationItem} onClick={() => {
                                if (indexActive === index + 1) {
                                    setIndexActive(0)
                                } else {
                                    setIndexActive(index + 1)
                                }
                                if (value.path !== '#') {
                                    window.location.href = value.path
                                }
                            }}>
                                <span><Text>{value.title}</Text></span>
                                <span className={styles.icon} style={{backgroundImage: `url("${icExtend}")`}}/>
                            </div>
                            {
                                (indexActive === (index + 1) && value.items?.length > 0) &&
                                value.items.map((valueItem: any, index: number) => {
                                    return (
                                        <div className={styles.navigationItemContainer} key={index}>
                                            <h2>{valueItem.title}</h2>
                                            {
                                                valueItem?.contents?.length > 0 &&
                                                valueItem?.contents.map((item: any, indexItem: number) => {
                                                    return (
                                                        <p key={indexItem} onClick={() => {window.location.href = `${item?.slug}`}}>{item?.name}</p>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}
export default NavigationOnPhone