import React from "react"
import Text from "../../../../components/Intents/Intent"
import Language from "../../../../assets/translations/language"

//styles
import  styles from './styles/framework.module.scss'

//icons & images

const Framework = (props: any) => {
    return(
        <div className={`${styles.framework} `}>
            <div className={`${styles.main} container`}>
                <div className={styles.content}>
                    <h2> <Text>{Language.technology_first_framework_title}</Text></h2>
                    <p> <Text>{props.language ? props?.data?.attributes?.langFramework?.description_1 : props?.data?.attributes?.langFramework?.description_1_en}</Text></p>
                    <p> <Text>{props.language ? props?.data?.attributes?.langFramework?.description_2 : props?.data?.attributes?.langFramework?.description_2_en}</Text></p>
                    {/* <p> <Text>{Language.technology_first_framework_content3}</Text></p> */}
                </div>
                <div className={styles.image}>
                    <div className={`${styles.img} background-image`} style={{backgroundImage:`url("${props?.data?.attributes?.langFramework?.image?.data?.attributes?.url}")`}}/>
                </div>
            </div>
        </div>
    )
}
export default Framework