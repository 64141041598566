import { useEffect, useState } from 'react'
import Banner from "../../components/Banner/Banner"
import ShowCaseView from "./component/ShowCaseView/ShowCaseView"
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb"
import Page from "../../components/ComponentPage/Page"
import { useTranslation } from 'react-i18next'
import { getShowCase } from '../../Showcase/showcase'

const ShowCase = () => {
    const[dataShowCase, setDataShowCase] = useState();
        const getData = () => {
            getShowCase().then((result: any) => {
                setDataShowCase(result);
            });
        }
        const {i18n} = useTranslation();
        const language = i18n.language === "vi";
        
        useEffect(() => {
            getData();
        }, [])

    const breadcrumb = {
        root: "Home",
        path: "Showcase",
    }

    return (
        <Page>
            {
                dataShowCase &&
                <div>
                    <Banner/>
                    <Breadcrumb data={breadcrumb}/>
                    <ShowCaseView data={dataShowCase}  language={language} />
                </div>
            }
            {/* <ShowCaseItem data={dataShowCase} language={language}/> */}
        </Page>
    )
}
export default ShowCase
