import React, { useState } from "react"
import Text from "../../Intents/Intent"
import Language from "../../../assets/translations/language"
import LanguageSelector from "../../Language/LanguageSelector"
import NavigationOnPhone from "./component/NavigationOnPhone/NavigationOnPhone"
import { Technology } from "../../../utils/enums/technology/technology_constant"

//styles
import styles from "./styles/header.module.scss"

//icons & images
import icLogoPhone from "../../../assets/images/image-logo.webp"
import icLogoVi from "../../../assets/images/image-logo-vi.svg"
import icLogoEn from "../../../assets/images/image-logo-en.svg"
import icDrop from "../../../assets/icons/ic-arrow-down.svg"
import icVI from "../../../assets/icons/ic-language-vi.svg"
import icUs from "../../../assets/icons/ic-eng-us.svg"
import icBar from "../../../assets/icons/ic-bar.svg"
import icCloseBar from "../../../assets/icons/ic-close-bar.svg"
import icArrow from "../../../assets/icons/ic-arrow-right.svg"
import imgMenuTechnology from "../../../assets/images/image-menu-technology.png"
import imgMenuService1 from "../../../assets/images/image-menu-service-1.png"
import imgMenuService2 from "../../../assets/images/image-menu-service-2.png"

interface navLinksInterface {
  title: keyof typeof Language
  path: string
  left_contents: any
  right_contents: any
}

const Header = () => {
  const [isShowModal, setIsShowModal] = useState(false)
  const [iconLanguage, setIconLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "en"
  )
  const [hideLanguageOpt, setHideLanguageOpt] = useState(true)

  const handleLanguage = (language: string) => {
    setIconLanguage(language)
    localStorage.setItem("language", language)
  }

  const navLinksInit: navLinksInterface[] = [
    {
      title: "menu_services",
      path: "/service",
      left_contents: [
        {
          label: Language.menu_services,
          values: [
            {
              slug: "/service/software-development",
              title: Language.our_expertise_title_item_2,
              has_translate: true
            },
            {
              slug: "/service/website-application",
              title: Language.home_whatwedo_subTitle_1,
              has_translate: true
            },
            {
              slug: "/service/mobile-application",
              title: Language.home_whatwedo_subTitle_2,
              has_translate: true
            }
            // {
            //     slug: '/service/software-support',
            //     title: Language.our_expertise_title_item_4,
            //     has_translate: true
            // },
          ]
        }
      ],
      right_contents: [
        // {
        //     label: Language.home_whatwedo_subTitle_1,
        //     image_url: imgMenuService1,
        //     slug: '/service/website-application',
        //     has_icon: true
        // },
        // {
        //     label: Language.home_whatwedo_subTitle_2,
        //     image_url: imgMenuService2,
        //     slug: '/service/mobile-application',
        //     has_icon: true
        // },
        {
          label: Language.footer_our_expertise_title_item_4,
          image_url: imgMenuService1,
          slug: "/service/ui-ux",
          has_icon: true
        },
        {
          label: Language.footer_our_services_title_item_2,
          image_url: imgMenuService2,
          slug: "/service/qa-testing",
          has_icon: true
        }
      ]
    },
    {
      title: "menu_technology",
      path: "/technology",
      left_contents: [
        {
          label: Language.technology_first_backend,
          values: [
            {
              slug: "/technology/golang",
              title: Technology.golang
            },
            {
              slug: "/technology/java",
              title: Technology.java
            },
            {
              slug: "/technology/nodejs",
              title: Technology.nodejs
            },
            {
              slug: "/technology/php",
              title: Technology.php
            }
          ]
        },
        {
          label: Language.technology_first_frontend,
          values: [
            {
              slug: "/technology/reactjs",
              title: Technology.reactjs
            },
            {
              slug: "/technology/vuejs",
              title: Technology.vuejs
            },
            // {
            //     slug: '/technology/angular',
            //     title: Technology.angular
            // },
            {
              slug: "/technology/flutter",
              title: Technology.flutter
            }
            // {
            //     slug: '/technology/ios',
            //     title: Technology.ios
            // },
            // {
            //     slug: '/technology/android',
            //     title: Technology.android
            // },
          ]
        }
      ],
      right_contents: [
        {
          label: Language.menu_technology_link,
          image_url: imgMenuTechnology,
          slug: "/technology",
          has_icon: false
        }
      ]
    },
    {
      title: "header_showcase",
      path: "/showcase",
      left_contents: [],
      right_contents: []
    },
    {
      title: "menu_contact_us",
      path: "/contact-us",
      left_contents: [],
      right_contents: []
    }
  ]

  return (
    <>
      <div className={`${styles.header}`}>
        <div className={`container ${styles.headerContainer}`}>
          <div
            className={`${styles.logo} background-image`}
            onClick={() => {
              window.location.href = "/"
            }}
            style={{
              backgroundImage: `url("${
                window.innerWidth > 769
                  ? iconLanguage === "en"
                    ? icLogoEn
                    : icLogoVi
                  : icLogoPhone
              }")`
            }}
          />
          <div className={styles.menu}>
            {navLinksInit.map((value, index) => (
              <div
                className={`${
                  window.location.href.includes(value.path) ? styles.active : ""
                } ${styles.menuItem}`}
                key={index}
              >
                <div
                  className={styles.title}
                  onClick={() => {
                    if (value.path !== "/service") {
                      window.location.href = value.path
                    }
                  }}
                >
                  <Text>{Language[value.title]}</Text>
                </div>
                {(value?.left_contents?.length > 0 ||
                  value?.right_contents?.length > 0) && (
                  <div className={styles.modal}>
                    <div className={`container ${styles.container}`}>
                      {value?.left_contents?.length > 0 && (
                        <div className={styles.left}>
                          {value?.left_contents?.map(
                            (left_content: any, idx: number) => {
                              return (
                                <div
                                  className={styles.leftContent}
                                  key={`${index}${idx}`}
                                >
                                  <span className={styles.subTitle}>
                                    <Text>{left_content?.label}</Text>
                                  </span>
                                  {left_content?.values?.length > 0 &&
                                    left_content?.values?.map(
                                      (item: any, itemIndex: number) => {
                                        if (item?.has_translate) {
                                          return (
                                            <span
                                              className={styles.item}
                                              onClick={() =>
                                                (window.location.href =
                                                  item?.slug)
                                              }
                                              key={`${index}${idx}${itemIndex}`}
                                            >
                                              <Text>{item?.title}</Text>
                                            </span>
                                          )
                                        } else {
                                          return (
                                            <span
                                              className={styles.item}
                                              onClick={() =>
                                                (window.location.href =
                                                  item?.slug)
                                              }
                                              key={`${index}${idx}${itemIndex}`}
                                            >
                                              {item?.title}
                                            </span>
                                          )
                                        }
                                      }
                                    )}
                                </div>
                              )
                            }
                          )}
                        </div>
                      )}
                      {value?.right_contents?.length > 0 && (
                        <div className={styles.rightContainer}>
                          <div className={styles.line} />
                          <div className={styles.right}>
                            <div className={styles.fluidContainer}>
                              {value?.right_contents?.map(
                                (right_content: any, idx: number) => {
                                  if (right_content?.has_icon) {
                                    return (
                                      <div
                                        className={styles.itemContainerService}
                                        key={`${index}${idx}`}
                                        onClick={() =>
                                          (window.location.href =
                                            right_content?.slug)
                                        }
                                      >
                                        <div>
                                          <div
                                            className={`${styles.itemImage} background-image`}
                                            style={{
                                              backgroundImage: `url("${right_content?.image_url}")`
                                            }}
                                          />
                                          <p className={styles.itemTitle}>
                                            <Text>{right_content?.label}</Text>
                                          </p>
                                        </div>
                                        <span className={styles.linkMore}>
                                          <Text>{Language.read_more}</Text>
                                          <img
                                            src={icArrow}
                                            alt={"icon arrow"}
                                          />
                                        </span>
                                      </div>
                                    )
                                  } else {
                                    return (
                                      <div
                                        className={styles.itemContainerService}
                                        key={`${index}${idx}`}
                                        onClick={() =>
                                          (window.location.href =
                                            right_content?.slug)
                                        }
                                      >
                                        <div
                                          className={`${styles.itemImage} background-image`}
                                          style={{
                                            backgroundImage: `url("${right_content?.image_url}")`
                                          }}
                                        />
                                        <p className={styles.question}>
                                          <Text>{right_content?.label}</Text>
                                        </p>
                                      </div>
                                    )
                                  }
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div
              className={`${styles.languageSelectBox}`}
              onClick={() => {
                setHideLanguageOpt(!hideLanguageOpt)
              }}
            >
              <div
                className={`${styles.iconLanguage} background-image`}
                style={{
                  backgroundImage:
                    iconLanguage === "en" ? `url("${icUs}")` : `url("${icVI}")`
                }}
              />
              <div
                className={`${styles.iconDrop} background-image`}
                style={{ backgroundImage: `url("${icDrop}")` }}
              />
              <div
                className={`${styles.option} ${
                  hideLanguageOpt ? "" : styles.unhidden
                }`}
                onMouseLeave={() => {
                  setHideLanguageOpt(true)
                }}
              >
                <LanguageSelector
                  iconLanguage={iconLanguage}
                  handleLanguage={handleLanguage}
                />
              </div>
            </div>
            <div
              className={`${styles.bar} background-image`}
              onClick={() => {
                document.body.style.overflow = isShowModal ? "unset" : "hidden"
                setIsShowModal(!isShowModal)
              }}
              style={{
                backgroundImage: !isShowModal
                  ? `url("${icBar}")`
                  : `url("${icCloseBar}")`
              }}
            />
          </div>
        </div>
      </div>
      {isShowModal && <NavigationOnPhone />}
    </>
  )
}
export default Header
